import React, { useContext, useEffect, useState } from 'react';
import {
  GelForm, GelFormField, GelTextInput,
  useGelFormData,
  GelButton,
  GelBoxLayout,
  GelScreenDetectorContext,
  GelContainerLite,
  GelRowLayout,
  GelIcon,
  GelLabel,
  GelLink,
  GelHeading3,
  GelParagraph,
  GelSpinner
} from '@tal-gel/components';

import { getGelTokens } from '@tal-gel/theming';
import { useNavigate } from "react-router-dom";
import { AccountApi } from '../../common/api/auth.provider';
import { UserContext } from '../../common/usercontext/user.context';
import { AdobeClickType } from '../../constants/constants';
import styled from '@emotion/styled';
import FundBanner from "../../components/fundBanner";
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';
import { useCookies } from 'react-cookie';
import { useGetConfirmMobilePageContentData } from '../../common/api/graphQLDataFetch';

const ConfirmMobile = () => {
  const { global: { sizeNone, sizeBaseUnit, themeColorTextDanger, sizeBaseX5, themeColorBackgroundLight, themeColorGrayT20, themeColorGrayT40, sizeBaseX2 } } = getGelTokens()

  const navigate = useNavigate();
  const { screen, isXsScreen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
  // TODO: investigate this useState
  const [loginStatusMessage, _] = useState("");
  const { setUserContext } = useContext(UserContext);
  const [{ tpid }] = useCookies(["tpid"]);

  const { data, isLoading } = useGetConfirmMobilePageContentData()

  let userPhoneNumber = "";

  const {
    formData,
    onFormDataReset,
    resetForm
  } = useGelFormData({
    mobileNumber: ''
  });

  const { contextData } = useContext(UserContext)

  const ResetMobileDiv = styled.div(() => ({
    paddingBottom: sizeBaseX5
  }));

  useEffect(() => {
    AdobeAnalytics.PushPageViewEvent(tpid)

    if (contextData.memberDetails?.mobileNumber) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      userPhoneNumber = contextData.memberDetails?.mobileNumber;
      formData.mobileNumber = contextData.memberDetails?.mobileNumber;
    }
    else {
      navigate("/error");
    }

  }, [tpid]);

  const onSubmit = () => {
    setUserContext({ memberDetails: { factorId: '', stateToken: contextData.memberDetails?.stateToken, mobileNumber: userPhoneNumber }, claims: [] });

    AdobeAnalytics.PushClickEvent(tpid, data?.confirmButtonText, AdobeClickType.Button)


    AccountApi.sendMfaSms(contextData.memberDetails?.factorId!, contextData.memberDetails?.stateToken!)
      .then(response => response.json())
      .then(data => {
        setUserContext({
          memberDetails: {
            factorId: contextData.memberDetails?.factorId,
            stateToken: data.stateToken,
            mobileNumber: contextData.memberDetails?.mobileNumber,
            oktaEmail: contextData.memberDetails?.oktaEmail
          },
          claims: []
        });
        navigate("/verifycode");
      })
      .catch(error => {
        console.error('failed to send MFA sms', error);
      });
    navigate("/verifycode");
  };

  const navigateToMobileReset = () => {
    AdobeAnalytics.PushClickEvent(tpid, data?.resetMobileLink, AdobeClickType.Link)
    navigate("/confirmmember");
  };

  if (isLoading)
    return (<GelSpinner medium overlay />)

  return (
    <GelContainerLite style={{
      paddingRight: sizeNone,
      paddingLeft: sizeNone,
      paddingBottom: sizeBaseUnit * 15,
      paddingTop: sizeBaseUnit * 15, overflow: "hidden"
    }}>
      <GelBoxLayout alignment="start" space={[1, 4]} gutter="none" >
        <GelForm
          labelAtTop={true}
          width={isXsScreen(screen) ?
            '100%' : sizeBaseUnit * 110
          }
          {...!isXsScreen(screen) && {
            labelWidth: sizeBaseUnit * 50
          }}
          fieldGutter={sizeBaseUnit * 10}
          disableOnSubmit
          onSubmit={onSubmit}
          reset={resetForm}
          onReset={onFormDataReset}
          parseResponseBody={false}
          style={{
            paddingRight: sizeBaseUnit * 16,
            paddingLeft: sizeBaseUnit * 16
          }}
        >
          <GelRowLayout gutter="medium" style={{ paddingLeft: sizeBaseUnit * 3 }}>
            <GelHeading3 style={{ padding: "20px 0px" }}>
              {data?.headerText}
            </GelHeading3>

            <GelParagraph>
              {data?.subText}
            </GelParagraph>

            <GelFormField
              label={data?.confirmMobileText}
            >
              <GelTextInput
                name="mobileNumber"
                value={formData.mobileNumber}
                readOnly='true'
                style={{
                  backgroundColor: themeColorBackgroundLight,
                  borderColor: themeColorGrayT20,
                  color: themeColorGrayT40
                }}
              />
            </GelFormField>


            <GelFormField width="auto" style={{ paddingTop: sizeBaseUnit * 4, paddingBottom: sizeBaseUnit * 4 }}>
              <GelButton
                name="" style={{
                  width: "100%"
                }}
                primary large submit
              >
                {data?.confirmButtonText}
              </GelButton>
            </GelFormField>

            <ResetMobileDiv>
              <GelLabel style={{
                fontWeight: 100,
                paddingRight: sizeBaseX2
              }}>
                {data?.resetMobileText}
              </GelLabel>
              <GelLink onClick={navigateToMobileReset}>
                {data?.resetMobileLink}
              </GelLink>
            </ResetMobileDiv>

            <GelBoxLayout space="auto">
              {loginStatusMessage &&
                <GelLabel style={{ color: themeColorTextDanger }}>
                  <GelIcon color={themeColorTextDanger} name="AlertCircle" inline />
                  {loginStatusMessage}
                </GelLabel>}
            </GelBoxLayout>
          </GelRowLayout>
        </GelForm>
        {isLargerThanSmScreen(screen) &&
          <FundBanner />}
      </GelBoxLayout>
    </GelContainerLite>
  );
};

export default ConfirmMobile;
import React, { useContext } from "react";
import {
    GelBoxLayout,
    GelButton,
    GelContainerLite,
    GelLink,
    GelScreenDetectorContext,
    GelPageLayoutContext,
    GelPageLayoutContextType
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { UserContext } from "../common/usercontext/user.context";
import AuthenticationService from "../common/authentication/auth.service";
import { AdobeClickType } from "../constants/constants";
import { useCookies } from "react-cookie";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import { SuperFundPrefixEnum, superFundPrefixPathMap } from "./../common/enums/enums"
import { AdobeAnalytics } from "../common/analytics/adobe-analytics";
import { useGetFundBannerDetailsContent } from "../common/api/graphQLDataFetch";
import { isTokenActive } from "../common/helpers/helpers";

const Header = () => {
    const { global: { sizeBaseUnit, themeColorBorderDefault } } = getGelTokens();
    const { isStandAloneAuthenticated, clearSession } = AuthenticationService();
    const navigate = useNavigate();
    const { contextData } = useContext(UserContext)
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const { openSideNav } = useContext(GelPageLayoutContext) as GelPageLayoutContextType;
    const [{ aid, tpid }] = useCookies(['aid','tpid']);
    const { authState } = useOktaAuth();

    const isNeedHelpVisible = contextData.claims?.length > 0 && !contextData.memberDetails?.displayLegalDisclosure;

    const { data } = useGetFundBannerDetailsContent()



    const signOut = async () => {
        AdobeAnalytics.PushClickEvent(tpid, "Log Out", AdobeClickType.Button)
        clearSession();
    };

    const closeNavbar = () => {
        if (isXsScreen(screen)) {
            openSideNav(false);
        }
    }

    /**
     * Handles logo click navigation based on authentication state and fund prefix.
     *
     * @description
     * This function determines the navigation path based on the user's authentication state:
     * - If no authentication state exists, the function exits without performing any action.
     * - If the user is unauthenticated or does not have a session token, it navigates to the fund-specific login page.
     * - If the user is authenticated and not on the "YourDeclaration" page, it navigates to the claims page.
     * - If the user is authenticated and already on the "YourDeclaration" page, it does nothing.
     *
     * @returns {void}
     *
     * @example
     * // Scenario: Unauthenticated user with AMP fund prefix
     * handleLogoClick(); // Navigates to the AMP fund login page
     *
     * @example
     * // Scenario: Authenticated user not on the "YourDeclaration" page
     * handleLogoClick(); // Navigates to "/claims"
     *
     * @example
     * // Scenario: Authenticated user already on the "YourDeclaration" page
     * handleLogoClick(); // Does nothing
    */
    const handleLogoClick = (): void => {
        if (!authState) return;

        if (window.location.hash === "#/yourDeclaration") return;

        if (isTokenActive(aid)) {
            navigate("/claims");
            return;
        }

        if (!authState.isAuthenticated && !isStandAloneAuthenticated) {
            navigate(superFundPrefixPathMap[tpid] || superFundPrefixPathMap[SuperFundPrefixEnum.AMP]); // SuperFundPrefixEnum.AMP act as a default route for login
            return;
        }
    };

    const handleNeedHelpLinkClick = () => {
        // TODO: Handle the link name; it should be moved to Contentful. 

        AdobeAnalytics.PushClickEvent(tpid, "Need Help?", AdobeClickType.Link)
    } 

    return (
        <GelContainerLite gutter="medium"
            style={{ boxShadow: `0 4px 2px -2px ${themeColorBorderDefault}` }}
        >
            <GelBoxLayout space={isXsScreen(screen) ? [20, 1, 1] : [20, 0.5, 0.5]} gutter={1} >
                <LogoWrapper onClick={handleLogoClick}>
                    <img id="brand-logo" alt="logo" src={data?.brandLogo.url}
                        style={{ display: "block", margin: "auto" }} />
                </LogoWrapper>

                <div style={{ float: "right", paddingRight: sizeBaseUnit * 3 }} onClick={closeNavbar}>
                    {isNeedHelpVisible && <GelLink href="#/help" onClick={handleNeedHelpLinkClick}>Need Help?</GelLink>}
                </div>

                <div style={{ float: "right", height: sizeBaseUnit * 12, paddingTop: sizeBaseUnit }}>
                    {isStandAloneAuthenticated &&
                        <GelButton secondary medium onClick={signOut} style={{ width: sizeBaseUnit * 25 }}>
                            Log Out
                        </GelButton>}
                </div>
            </GelBoxLayout>
        </GelContainerLite>
    );
};

const LogoWrapper = styled.div`
    display: flex;
    float: left;
    cursor: pointer;
`

export default Header;
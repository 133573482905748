import {
    GelContainerLite,
    GelBoxLayout,
    GelLabel,
    GelIcon,
} from
    '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import React from 'react';

const PageNotFound = () => {
    const { global: { themeColorTextDanger } } = getGelTokens()
    
    return (
        <GelContainerLite gutter="xlarge">
            <GelBoxLayout space="auto">
                <GelLabel style={{ color: themeColorTextDanger }}>
                    <GelIcon color={themeColorTextDanger} name="AlertCircle" inline />
                    The requested page is not valid. Please check the URL and try again.
                </GelLabel>
            </GelBoxLayout>
        </GelContainerLite>
    );
}

export default PageNotFound;
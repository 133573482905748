import React, { useEffect } from "react";
import {
    GelContainerLite,
    GelBoxLayout,
    GelLabel,
    GelIcon,
    GelSpinner
} from
    '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { useLocation } from "react-router-dom";
import { HttpStatusCode } from "axios";
import { useCookies } from "react-cookie";
import { OktaErrors } from "../../common/models/enums/oktaErrors.model";
import { AdobeAnalytics } from "../../common/analytics/adobe-analytics";
import { useGetErrorPagesContent } from "../../common/api/graphQLDataFetch";

const ServerError: React.FC = () => {
    const location = useLocation();
    const [{ tpid }] = useCookies(["tpid"]);

    const { data, isLoading } = useGetErrorPagesContent()

    useEffect(() => {
        AdobeAnalytics.PushPageViewEvent(tpid)

    }, [tpid]);

    if (isLoading)
        return <GelSpinner medium overlay />;


    const ErrorMessage = (): React.ReactNode => {
        const { sessionTerminatedMessage, forbiddenMessage, expiredActivationErrorMessage, unHandledErrorMessage } = data ?? {}
        const { themeColorTextDanger } = getGelTokens().global
        const { state } = location

        let errorMessage

        if (!state) {
            errorMessage = unHandledErrorMessage
        }

        switch (state?.errorCode) {
            case HttpStatusCode.Unauthorized:
                errorMessage = sessionTerminatedMessage
                break;
            case HttpStatusCode.Forbidden:
                errorMessage = forbiddenMessage
                break;
            case OktaErrors.EXPIRED_OR_USED_LINK:
                errorMessage = <div dangerouslySetInnerHTML={{ __html: expiredActivationErrorMessage }} style={{ all: "unset" }} />
                break;
            default:
                errorMessage = unHandledErrorMessage
                break;
        }

        return (
            <GelLabel style={{ color: themeColorTextDanger }}>
                <GelIcon color={themeColorTextDanger} name="AlertCircle" inline />
                {errorMessage}
            </GelLabel>
        )
    }

    return (
        <GelContainerLite gutter="xlarge">
            <GelBoxLayout space="auto">
                <ErrorMessage />
            </GelBoxLayout>
        </GelContainerLite>
    );
}

export default ServerError;
import React, { useContext, useEffect, useState } from 'react';
import {
  GelForm, GelFormField,
  GelPasswordInput,
  useGelFormData,
  GelButton,
  GelBoxLayout,
  GelScreenDetectorContext,
  GelContainerLite,
  GelSelect,
  GelIcon,
  GelLabel,
  GelLink,
  GelEmailInput,
  GelRowLayout,
  GelHeading3,
  GelParagraph,
  GelSpinner
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { AuthnTransactionData, TRANSACTION_STATUS, getOktaUsername } from '../../common/authentication/auth.utils';
import { useNavigate, useParams } from "react-router-dom";
import { useOktaAuth } from '@okta/okta-react';
import { AccountApi } from '../../common/api/auth.provider';
import { UserContext } from '../../common/usercontext/user.context';
import { GET_LOGINPAGE_CONTENT_QUERY } from '../../graphql/queries/graphql-contentful-queries';
import { ThemeContext } from '../.././common/usercontext/themes.context';
import FundBanner from "../../components/fundBanner";
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { AdobeClickType, API } from '../../constants/constants';
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';
import { useGetSuperfundData } from '../../common/api/graphQLDataFetch';

// TODO: refactor and simplify this page
const LoginPage = () => {
  const { global: { sizeBaseUnit, sizeNone, themeColorTextDanger } } = getGelTokens();

  const navigate = useNavigate();
  const { oktaAuth, authState } = useOktaAuth();
  const { screen, isXsScreen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
  const { setUserContext } = useContext(UserContext);
  const { setThemeContext } = useContext(ThemeContext);
  const [loginStatusMessage, setloginStatusMessage] = useState("");
  const [superfundId, setSuperFund] = useState(null);

  const [contentDataLoaded, setcontentDataLoaded] = useState(false);
  const [contentfulData, setcontenfulData] = useState<null | any>(null);
  const [isSuperFundFromUrl, setIsSuperFundFromUrl] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setssoCookies, removeCookie] = useCookies(['aid', 'rid', 'ssoid', 'tpid']);

  const superFundData = useGetSuperfundData()
  let { fund } = useParams();

  const {
    formData,
    onFormDataChange,
    onFormDataReset,
    resetForm
  } = useGelFormData({
    email: '',
    password: '',
    superfundId: ''
  });

  const onSuperFundChange = event => {
    setSuperFund(event.target.value);
  };


  useEffect(() => {
    if (authState && authState.isAuthenticated) {
      navigate("/claims");
    }

    const fundArgument = fund ?? ""
    AdobeAnalytics.PushPageViewEvent(fundArgument)

    if (fund && !superFundData.isLoading) {
      validateUrlParamFund()

    } else {
      loadDataGeneric();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [superFundData.isLoading, fund]);

  const loadDataGeneric = () => {
    axios.post(API.CONTENTFUL_URL, {
      query: GET_LOGINPAGE_CONTENT_QUERY,
    })
      .then(async (response) => {
        setcontentDataLoaded(true);
        setcontenfulData(response.data?.data.items.items[0]);
      });

    removeCookie('tpid');
  }

  const validateUrlParamFund = () => {
    if (!fund) {
      return
    }

    const selectedSuperFund = superFundData.data?.superFundList?.find(superFund => superFund.displayName === fund);

    if (!selectedSuperFund) {
      navigate("/invalidfund")
      return
    }

    let selectedSuperFundId = selectedSuperFund.superfundId;
    let selectedSuperFundPrefix = selectedSuperFund.superFundPrefix.toLowerCase();
    let selectedSuperFundTheme = selectedSuperFund.theme;

    setssoCookies('tpid', selectedSuperFundPrefix);

    setThemeContext({
      theme: selectedSuperFundTheme,
      fund: fund
    });

    setIsSuperFundFromUrl(true);
    setSuperFund(selectedSuperFundId);

    axios.post(API.CONTENTFUL_URL, {
      query: GET_LOGINPAGE_CONTENT_QUERY,
      variables: {
        fundName: selectedSuperFundPrefix
      }
    })
      .then(async (response) => {
        setcontentDataLoaded(true);
        setcontenfulData(response.data?.data.items.items[0]);
      });

  }

  const getSuperFundNameForAdobeAnalytics = (): string => {
    return superFundData.data?.superFundList?.find(
      item => item.superfundId.toString() === superfundId
    )?.superFundPrefix.toLowerCase() ?? "";
  };

  const onSubmit = () => {
    AdobeAnalytics.PushClickEvent(getSuperFundNameForAdobeAnalytics(), contentfulData?.loginButtonText, AdobeClickType.Button)

    var umbrellaFunds = superFundData.data?.superFundList?.find(x => x.superfundId == superfundId)?.umbrellaFundIds?.split(',') ?? [];
    umbrellaFunds.push(superfundId);
    oktaLogin(umbrellaFunds);

    setssoCookies('tpid', getSuperFundNameForAdobeAnalytics());
  };

  const oktaLogin = (superFundIds: string[]) => {

    var superfundId = superFundIds.pop();

    const oktaUsername = getOktaUsername(superfundId!, formData.email);
    oktaAuth.revokeAccessToken()
      .then(() => {
        oktaAuth.signIn({
          username: oktaUsername,
          password: formData.password
        })
          .then((res: AuthnTransactionData) => {
            if (res.status === TRANSACTION_STATUS.MFA_REQUIRED) {
              setloginStatusMessage("Valid credentials, MFA Required");
              const factor = res.factors?.find(f => f.factorType === 'sms');
              if (!factor) {
                console.log(`User not enrolled in sms factor`);
              }
              const phoneNumber = factor?.profile.phoneNumber.replaceAll(' ', '').replaceAll('X', '*');
              navigate("/verifycode");
              setUserContext({ memberDetails: { stateToken: res.data?.stateToken!, mobileNumber: phoneNumber, factorId: factor?.id, policyManagerId: Number(superfundId) }, claims: [] });
              AccountApi.sendMfaCode(factor?.id, res.data?.stateToken!)
                .then(response => response.json())
                .then(data => {
                  console.log("sms sent", data)
                })
                .catch(httpError => {
                  console.log('failed to send MFA sms', httpError);
                  setloginStatusMessage("Failed sending sms. Please try again");
                });

            } else if (res.status === TRANSACTION_STATUS.SUCCESS) {
              console.log('MFA not required for member, authentication succcessful');
              // Store automation test account password in session storage for redirect to EFT/TFN microsites
              //Storage.setItem(SESSION_STORAGE_KEY.ACCOUNT_TYPE, ACCOUNT_TYPE.AUTOMATION_ACCOUNT);
              // Retreive ID & Access token from Okta

              oktaAuth.signInWithRedirect({ sessionToken: res.data?.sessionToken });
            } else if (res.status === TRANSACTION_STATUS.LOCKED_OUT) {
              setloginStatusMessage("Your account has been locked. Please reach out to your Claims Consultant");
            }
          })
          .catch(httpError => {
            console.log(superFundIds, "super funds ids")
            if (superFundIds?.length > 0) {
              console.log('check for umbrellafunds');
              oktaLogin(superFundIds);
            } else {
              console.log('failed to login', httpError);
              setloginStatusMessage(contentfulData?.errorMessageContent.invalidLoginMsg);
            }
          });
      })
  }

  const navigateToSignUp = () => {
    AdobeAnalytics.PushClickEvent(getSuperFundNameForAdobeAnalytics(), contentfulData?.signUpLink, AdobeClickType.Link)

    navigate("/signup");
  };

  const navigateToPasswordReset = () => {
    AdobeAnalytics.PushClickEvent(getSuperFundNameForAdobeAnalytics(), contentfulData?.forgotPasswordLink, AdobeClickType.Link)

    navigate("/forgotPassword");
  };

  if (!contentDataLoaded || superFundData.isLoading)
    return (<GelSpinner medium overlay />)

  return (
    <GelContainerLite style={{
      paddingRight: sizeNone,
      paddingLeft: sizeNone,
      paddingBottom: sizeBaseUnit * 15,
      paddingTop: sizeBaseUnit * 15,
      overflow: "hidden"
    }}>
      <GelBoxLayout alignment="start" space={[1, 4]} gutter="none">
        <GelForm
          labelAtTop={true}
          width={isXsScreen(screen) ?
            '100%' : sizeBaseUnit * 110
          }
          {...!isXsScreen(screen) && {
            labelWidth: sizeBaseUnit * 50
          }}
          fieldGutter={sizeBaseUnit * 10}
          disableOnSubmit
          onSubmit={onSubmit}
          reset={resetForm}
          onReset={onFormDataReset}
          parseResponseBody={false}
          style={{
            paddingRight: sizeBaseUnit * 16,
            paddingLeft: sizeBaseUnit * 16
          }}
        >
          <GelRowLayout gutter="medium" style={{ paddingLeft: sizeBaseUnit * 3 }}>
            <GelHeading3>
              {contentfulData?.headerText}
            </GelHeading3>
            <GelParagraph style={{ paddingTop: sizeBaseUnit * 2 }}>{contentfulData?.subText}</GelParagraph>

            {!isSuperFundFromUrl &&
              <GelFormField
                label={contentfulData?.superFundText} >
                <GelSelect
                  name="superfund"
                  onChange={onSuperFundChange}
                  placeholder="Please select"
                  options={superFundData.data?.superFundList?.filter(superFund => superFund.hideInDropDown == false)?.map((superFund) => (
                    { label: superFund.superfundName, value: superFund.superfundId }
                  ))}
                  required
                  requiredErrorMsg={contentfulData?.errorMessageContent.superFundReqMsg}
                />
              </GelFormField>
            }

            <GelFormField
              label={contentfulData?.emailText} >
              <GelEmailInput
                name="email"
                onChange={onFormDataChange}
                required
                value={formData.email}
                requiredErrorMsg={contentfulData?.errorMessageContent?.emailReqMsg}
                formatErrorMsg={contentfulData?.errorMessageContent?.emailFormatMsg}
              />

            </GelFormField>

            <GelFormField
              label={contentfulData?.passwordText}
            >
              <GelPasswordInput
                name="password"
                value={formData.password}
                onChange={onFormDataChange}
                required
                requiredErrorMsg={contentfulData?.errorMessageContent.passwordReqMsg}
              />
            </GelFormField>

            {loginStatusMessage &&
              <GelBoxLayout space={[1, 8]}>
                <GelIcon color={themeColorTextDanger} name="AlertCircle" inline />
                <GelLabel style={{ color: themeColorTextDanger }}>
                  {loginStatusMessage}
                </GelLabel>
              </GelBoxLayout>
            }
            <GelFormField style={{ paddingTop: sizeBaseUnit * 4 }}>
              <GelButton
                name="" style={{
                  width: "100%"
                }}
                primary large submit
              >
                {contentfulData?.loginButtonText}
              </GelButton>
            </GelFormField>

            <div style={{ paddingTop: sizeBaseUnit * 4 }}>
              <GelLabel style={{
                fontWeight: 100
              }}>{contentfulData?.forgotPasswordText}
                <GelLink onClick={navigateToPasswordReset}> {contentfulData?.forgotPasswordLink}</GelLink>
              </GelLabel>
            </div>

            <div style={{ paddingBottom: sizeBaseUnit * 4 }}>
              <GelLabel style={{
                fontWeight: 100
              }}>
                {contentfulData?.signUpText}
                <GelLink onClick={navigateToSignUp}> {contentfulData?.signUpLink}</GelLink>
              </GelLabel>
            </div>

          </GelRowLayout>
        </GelForm>
        {isLargerThanSmScreen(screen) &&
          <FundBanner />}
      </GelBoxLayout>
    </GelContainerLite>
  );
};
export default LoginPage;

import React, { useContext, useEffect } from 'react';
import { CLAIM_ASSESSMENT_STATUS } from '../../constants/constants';
import Card from '../../common/components/card';
import {
    GelScreenDetectorContext,
    GelContainerLite,
    GelParagraph,
    GelHeading2,
    GelSpinner
} from '@tal-gel/components';
import Requirement from './requirement';
import BenefitType from './benefitType';
import NextPayment from './nextPayment';
import { getGelTokens } from '@tal-gel/theming';
import { UserContext } from '../../common/usercontext/user.context';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import DocumentUpload from './documentupload';
import ResponsiveContainer from "../../components/responsive.container";
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';
import { useGetHomePageContent } from "../../common/api/graphQLDataFetch"
import { useCookies } from 'react-cookie';


// TODO: When a user clicks on the "VIEW CLAIM DETAILS" button on the main page (Manage Your Claim/Home), the button redirects him to the next page as expected. However, the active state of the navigation bar does not update to reflect the current page. Investigate and resolve this issue to ensure proper navigation state is maintained throughout the user journey.
const ClaimsContainer = () => {
    const { global: { sizeNone, themeColorBackgroundLight, sizeBaseX15, sizeBaseX5, sizeBaseX10, sizeBaseX3 } } = getGelTokens();
    const { data, isFetched } = useGetHomePageContent()

    const navigate = useNavigate();
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const { contextData } = useContext(UserContext);
    const [{ tpid }] = useCookies();


    if (contextData.memberDetails?.memberNumber &&
        (contextData?.claims?.length === 0 || !contextData?.claims)) {
        navigate("/error");
    }

    useEffect(() => {
        AdobeAnalytics.PushFormStartEvent(tpid)
        AdobeAnalytics.PushPageViewEvent(tpid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const PageHeader = styled.div(() => ({
        padding: isXsScreen(screen) ? `${sizeBaseX15}px ${sizeBaseX5}px` : `${sizeBaseX15}px ${sizeBaseX10}px`,
        backgroundColor: themeColorBackgroundLight
    }));

    if (contextData.memberDetails?.displayLegalDisclosure)
        navigate("/yourDeclaration");


    const claimPadding = (index) => {
        if (isXsScreen(screen))
            return `0px ${sizeBaseX5}px ${sizeBaseX5}px ${sizeBaseX5}px`
        else
            return `0px ${sizeBaseX10}px ${index === contextData.claims.length - 1 ? sizeNone : sizeBaseX5}px ${sizeBaseX10}px`
    }

    if (!isFetched) {
        <GelSpinner medium overlay />;
    }

    return (
        <GelContainerLite style={{ padding: sizeNone, overflow: "hidden" }}>
            {contextData.claims?.length > 0 &&
                <PageHeader >
                    <GelHeading2>{data?.appTitle}</GelHeading2>
                    <GelParagraph style={{ paddingTop: sizeBaseX3 }}>{data?.appTitleDescription}</GelParagraph>
                </PageHeader>
            }
            <ResponsiveContainer style={{ paddingTop: `${sizeBaseX15}px`, paddingBottom: `${sizeBaseX15}px` }}>
                {contextData?.claims?.map((claim, index) => (
                    <div key={claim.claimNo} style={{ padding: claimPadding(index) }} >
                        <Card title={claim.claimTypeName} claimAssesmentStatus={claim?.claimAssesmentStatus}
                            buttonText={data?.buttonText} icon={"InfoCircle"}
                            subTitle={data?.subTitle} showFooter={true}>
                            <BenefitType claimNumber={claim.caseNumber} description={claim?.status.description} friendlyStatus={claim?.status.friendlyStatus}
                                benefitTypeText={claim.claimTypeName} isFromHomePage={true} />

                            {(claim?.claimAssesmentStatus !== CLAIM_ASSESSMENT_STATUS.RN) &&
                                <Requirement requirementList={claim.requirements.filter(rq => rq.type !== 1 && rq.type !== 3 && rq.type !== 4)}
                                    thirdPartyRequirement={claim?.status.thirdPartyRequirementInfoText} claimType={claim.claimTypeName}
                                    requirementStatus={data?.requirementCompletedStatus}
                                    thirdPartyRequirementStatus={data?.thirdPartyRequirementStatus}
                                    requirementInCompleteStatus={data?.requirementInCompleteStatus}
                                    requirementReviewStatus={data?.requirementReviewStatus} />}

                            {(claim?.claimAssesmentStatus !== CLAIM_ASSESSMENT_STATUS.RN) &&
                                <NextPayment message={data?.paymentMessage}
                                    isFuturePayment={claim.nextFuturePayment.isSuccess}
                                    futurePayments={claim.nextFuturePayment.value}
                                    claimType={claim.claimTypeName} />}

                            {(claim?.claimAssesmentStatus === CLAIM_ASSESSMENT_STATUS.RN)
                                &&
                                <DocumentUpload title={claim.claimTypeName} uploadDocumentText={data?.uploadDocumentText} uploadDocumentDescriptor={data?.uploadDocumentDescriptor} />}
                        </Card>
                    </div>
                ))}
            </ResponsiveContainer>
        </GelContainerLite>
    );
};

export default ClaimsContainer;
import React from 'react';
import { getGelTokens } from "@tal-gel/theming";
import {
    GelLink,
    GelBoxLayout,
    GelContainerLite,
    GelLabel

} from '@tal-gel/components';
import { useNavigate } from "react-router-dom";
import { AdobeClickType, NotificationItemIds } from '../../constants/constants';
import { useCookies } from 'react-cookie';
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';

interface documentUploadProps {
    uploadDocumentText: string;
    uploadDocumentDescriptor: string;
    title: string
}

const DocumentUpload = (props: documentUploadProps) => {
    const { global: { sizeBaseX3, sizeBaseX2, themeColorBackgroundLight, themeColorGrayT20 } } = getGelTokens()

    const navigate = useNavigate();
    const [{ tpid }, setClaimCookie] = useCookies(['selectedClaim', 'tpid']);

    const onClick = () => {
        AdobeAnalytics.PushClickEvent(tpid, props.uploadDocumentText, AdobeClickType.Link)

        setClaimCookie('selectedClaim', props.title);
        navigate("/claimdetails", { state: { selectedNotification: NotificationItemIds.OtherDocuments } });
    };

    return (
        <GelContainerLite gutter="medium" style={{
            paddingBottom: 0,
            paddingTop: 0,
            paddingLeft: sizeBaseX2 * 1,
            paddingRight: sizeBaseX2 * 1,
        }}>
            <GelBoxLayout alignmentIndividual={['center', null]}
                style={{
                    backgroundColor: themeColorBackgroundLight,
                    paddingTop: sizeBaseX3,
                    paddingBottom: sizeBaseX3,
                    paddingLeft: sizeBaseX3,
                    borderColor: themeColorGrayT20,
                }}>
                <GelLabel>
                    {props.uploadDocumentDescriptor}
                    <GelLink style={{ paddingLeft: sizeBaseX2 / 2 }} onClick={onClick}>
                        {props.uploadDocumentText}
                    </GelLink>
                </GelLabel>

            </GelBoxLayout>
        </GelContainerLite>

    );
};

export default DocumentUpload;
import axios from 'axios';
import React from 'react';
import { AdobeClickType, API } from '../../../constants/constants';
import { GET_MEMBER_DOCUMENT_QUERY } from '../../../graphql/queries/graphql-queries.constant';
import { useCookies } from 'react-cookie';
import {
    GelLink,
    GelLabel
} from "@tal-gel/components";
import SESSION_STORAGE_KEY from '../../../constants/storage.constant';
import { SaveFile } from '../../../common/file.utils';
import { AdobeAnalytics } from '../../../common/analytics/adobe-analytics';

const DocumentDownload = (props) => {
    const [{ aid, rid, ssoid, tpid }] = useCookies(['aid', 'rid', 'ssoid', 'tpid']);

    const handleDownload = () => {
        AdobeAnalytics.PushClickEvent(tpid, props.displayName, AdobeClickType.Link)

        axios.post(API.BASEURL, {
            query: GET_MEMBER_DOCUMENT_QUERY,
            variables: {
                documentIdentifier: props.documentIdentifier,
                fileName: props.displayName
            }
        },
            {
                headers: {
                    'x-aid': aid,
                    'x-rid': rid,
                    ssoid,
                    tpid,
                    'sid': window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN)
                }
            })
            .then(async (response) => {
                SaveFile(response.data.data.downloadDocument.content, props.displayName);
            })
            .catch((error) => {
                console.log(error)
            })
    };

    return (
        <>
            {props.documentIdentifier &&
                <GelLink onClick={() => handleDownload()}>
                    {props.displayName}
                </GelLink>}

            {!props.documentIdentifier &&
                <GelLabel style={{ fontWeight: 500 }}>
                    {props.displayName}
                </GelLabel>}
        </>
    );
};

export default DocumentDownload;
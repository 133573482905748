import {
    GelLabel,
    GelParagraph,
    GelButton,
    GelIcon,
    GelModal,
    GelContainerLite,
    GelCollapsible,
    GelBoxLayout,
    GelRowLayout,
    GelTag,
    GelScreenDetectorContext
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import { useContext, useEffect, useState } from "react";
import Uploader from "./uploader";
import FileTypes from "./fileTypes";
import React from "react";
import { AdobeClickType, PAYMENT_DETAILS_REQUIREMENTS, TFN_DETAILS_REQUIREMENTS } from "../../../constants/constants";
import OtherRequirement from "./otherRequirement";
import { useLocation } from "react-router-dom";
import { useCookies } from "react-cookie";
import { AdobeAnalytics } from "../../../common/analytics/adobe-analytics";

interface RequirementDocumentsProps {
    requirement: any;
    contentFullData: any;
    memberNumber: string;
    superfundId: number;
}

const RequirementDocuments = (props: RequirementDocumentsProps) => {
    const { global: { sizeBaseUnit, themeColorBorderDefault, fontFamilySans, fontWeightRegular, sizeBaseX2,
        sizeNone, sizeBaseX3, themeColorBorderWarning, themeColorTextDark, themeColorBorderDanger, themeColorIconDefault } } = getGelTokens()

    let requirementData = props.requirement;

    const location = useLocation();
    const [{ tpid }] = useCookies(['tpid']);
    const [openModal, setOpenModal] = useState(false);
    const [showRequirementDetails, setShowRequirementDetails] = useState(false);
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;

    useEffect(() => {
        const selectedNotification = location.state?.selectedNotification;
        setShowRequirementDetails(selectedNotification === requirementData.id);
    }, []);

    const onHeaderClick = () => {
        if (allowSectionCollapse)
            setShowRequirementDetails(!showRequirementDetails);

        AdobeAnalytics.PushClickEvent(tpid, props.requirement.description, AdobeClickType.Button)
    }

    let allowSectionCollapse = true;
    const onDisableCollapse = (allowCollapse: boolean) => {
        allowSectionCollapse = allowCollapse;
    }

    const handleViewSupportedTypesButtonClick = () => {
        AdobeAnalytics.PushClickEvent(tpid, props.contentFullData?.viewSupportedTypes, AdobeClickType.Tooltip)

        setOpenModal(true)
    }

    return (
        <div id={requirementData.id}>
            <GelContainerLite style={{
                borderTop: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                borderLeft: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                borderRight: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                width: "auto",
                cursor: "pointer"
            }}
                onClick={onHeaderClick}>
                <GelBoxLayout space={!isXsScreen(screen) ? [7, 0.5, 0.04] : [1, 0.01, 0.05]} gutter={sizeBaseUnit}
                    style={{
                        borderBottom: showRequirementDetails ? `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}` : "",
                        width: "auto",
                        padding: sizeBaseUnit * 4,
                        marginLeft: -sizeBaseUnit * 4,
                        marginRight: -sizeBaseUnit * 4
                    }}>

                    <GelLabel
                        style={{
                            fontFamily: fontFamilySans,
                            fontSize: sizeBaseUnit * 4,
                            fontWeight: fontWeightRegular
                        }}>
                        {props.requirement.friendlyText}
                    </GelLabel>


                    {(props.requirement.receivedDate || props.requirement.associatedDocuments.length) &&
                        <GelTag small warning style={{ height: "auto" }}>
                            <GelBoxLayout distribution='center' alignment='center' space={[1, 20]}
                                style={{
                                    paddingLeft: `${sizeBaseUnit}px`,
                                    paddingRight: `${sizeBaseX2}px`
                                }}
                                childStyle={{
                                    marginLeft: `-${sizeBaseUnit}px`,
                                    paddingRight: `${sizeNone}px`
                                }}>
                                <GelIcon
                                    name="Circle"
                                    width={sizeBaseUnit * 2}
                                    color={themeColorBorderWarning}
                                />

                                <GelParagraph style={{ fontSize: sizeBaseX3, color: themeColorTextDark }}>{props.contentFullData?.requirementInReview}</GelParagraph>
                            </GelBoxLayout>
                        </GelTag>
                    }


                    {!props.requirement.receivedDate && !props.requirement.associatedDocuments.length &&
                        <GelTag small danger style={{ height: "auto" }}>
                            <GelBoxLayout distribution='center' alignment='center' space={[0.5, 1]}
                                style={{
                                    paddingLeft: `${sizeBaseUnit}px`,
                                    paddingRight: `${sizeBaseX2}px`
                                }}
                                childStyle={{
                                    marginLeft: `-${sizeBaseUnit}px`,
                                    paddingRight: `${sizeNone}px`
                                }}>

                                <GelIcon
                                    name="Circle"
                                    width={sizeBaseUnit * 2}
                                    color={themeColorBorderDanger}
                                />

                                <GelParagraph style={{ fontSize: sizeBaseX3, color: themeColorTextDark }}>{props.contentFullData?.requirementRequired}</GelParagraph>
                            </GelBoxLayout>
                        </GelTag>
                    }


                    <GelIcon name={showRequirementDetails ? "ChevronUp" : "ChevronDown"} color={themeColorIconDefault} width="20px" height="20px"></GelIcon>
                </GelBoxLayout>

            </GelContainerLite>

            <GelContainerLite style={{
                borderBottom: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                borderLeft: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                borderRight: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                width: "auto"
            }}>
                <GelCollapsible open={showRequirementDetails} >
                    <GelContainerLite style={{ paddingBottom: sizeBaseUnit * 2 }}>
                        {!PAYMENT_DETAILS_REQUIREMENTS.includes(props.requirement.description.toUpperCase()) &&
                            !TFN_DETAILS_REQUIREMENTS.includes(props.requirement.description) &&
                            <div>
                                <GelRowLayout gutter="xsmall" style={{
                                    paddingTop: sizeBaseUnit,
                                    paddingBottom: sizeBaseUnit,
                                }}
                                >
                                    <GelLabel > 
                                        {props.contentFullData?.documentsToStart}
                                    </GelLabel>

                                    <GelParagraph style={{ paddingRight: "10px" }}>
                                        {props.contentFullData?.maxFileSize}
                                    </GelParagraph>

                                    <GelButton tertiary small onClick={handleViewSupportedTypesButtonClick}>
                                        {props.contentFullData?.viewSupportedTypes}
                                    </GelButton>

                                    <GelModal
                                        width="sm"
                                        height={sizeBaseUnit * 80}
                                        open={openModal}
                                        onClose={() => setOpenModal(false)}
                                        sticky
                                        title={props.contentFullData?.viewSupportedTypes}
                                    >
                                        <FileTypes />
                                    </GelModal>
                                </GelRowLayout>

                                <Uploader
                                    requirement={requirementData}
                                    contentFullData={props.contentFullData}
                                    setAllowCollapse={onDisableCollapse}
                                />
                            </div>
                        }

                        {PAYMENT_DETAILS_REQUIREMENTS.includes(props.requirement.description.toUpperCase()) &&
                            <div style={{
                                paddingTop: sizeBaseUnit * 2,
                                paddingBottom: sizeBaseUnit * 2,
                                marginLeft: -sizeBaseUnit * 4,
                                marginRight: -sizeBaseUnit * 4
                            }}>
                                <OtherRequirement key={props.requirement.id}
                                    contentFullData={props.contentFullData}
                                    requirement={props.requirement}
                                    memberNumber={props.memberNumber}
                                    requirementData={requirementData}>
                                </OtherRequirement>
                            </div>
                        }

                        {TFN_DETAILS_REQUIREMENTS.includes(props.requirement.description) &&
                            <div style={{
                                paddingTop: sizeBaseUnit * 2,
                                paddingBottom: sizeBaseUnit * 2,
                                marginLeft: -sizeBaseUnit * 4,
                                marginRight: -sizeBaseUnit * 4
                            }}>
                                <OtherRequirement key={props.requirement.id}
                                    contentFullData={props.contentFullData}
                                    requirement={props.requirement}
                                    memberNumber={props.memberNumber}
                                    requirementData={requirementData}>
                                </OtherRequirement>
                            </div>
                        }
                    </GelContainerLite>
                </GelCollapsible>
            </GelContainerLite>
        </div>
    );
};
export default (RequirementDocuments);

import React, { useContext, useEffect, useState } from 'react';
import {
  useGelFormData,
  GelForm, GelFormField, GelTextInput,
  GelButton,
  GelBoxLayout,
  GelContainerLite,
  GelSelect,
  GelIcon,
  GelLabel,
  GelPhoneNumberInput,
  GelScreenDetectorContext,
  GelRowLayout,
  GelStandardDateInput,
  GelReCaptcha,
  GelSpinner,
  GelParagraph,
  GelHeading3
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { useNavigate } from 'react-router-dom';
import { AdobeClickType, API, ReCaptchaSettings } from '../../constants/constants';
import axios from 'axios';
import FundBanner from "../../components/fundBanner";
import { CONFIRM_USER_DETAILS, UPDATE_MOBILE_NUMBER } from '../../graphql/queries/graphql-mutations';
import { ConfirmUserStatus, UpdateMobileStatus } from '../../common/models/accountStatus.model';
import { UserContext } from '../../common/usercontext/user.context';
import { AccountApi } from '../../common/api/auth.provider';
import SESSION_STORAGE_KEY from '../../constants/storage.constant';
import { useCookies } from 'react-cookie';
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';
import { useGetConfirmYourDetailsPageContent, useGetSuperfundData } from '../../common/api/graphQLDataFetch';

const ConfirmMember: React.FC = () => {
  const { global: { sizeBaseUnit, sizeNone, themeColorIconDanger, themeColorTextDanger } } = getGelTokens()

  const [superfundId, setSuperFund] = useState(null);
  const { screen, isXsScreen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
  const navigate = useNavigate();
  const [confirmStatusMessage, setconfirmStatusMessage] = useState("");
  const [userDetailsVerified, setuserDetailsVerified] = useState(false);
  const [apiUpdateInProgress, setapiUpdateInProgress] = useState(false);
  const { contextData, setUserContext } = useContext(UserContext);
  const [status, setStatus] = useCookies(['confirmMember']);
  const [{ tpid }] = useCookies(["tpid"])


  const superFundData = useGetSuperfundData()
  const confirmDetailsData = useGetConfirmYourDetailsPageContent()

  let isCaptchaEnabled = ReCaptchaSettings.ReCaptcha_Enabled === "1";

  const {
    formData,
    onFormDataChange,
    resetForm
  } = useGelFormData({
    reCaptchaToken: null,
    superFund: '',
    memberNumber: '',
    dateOfBirth: '',
    mobileNumber: ''
  });


  useEffect(() => {
    const fundPrefux = tpid ?? ""
    AdobeAnalytics.PushPageViewEvent(fundPrefux)

    if (!isPageValidUpdateContext())
      navigate("/error");

    if (window.sessionStorage.getItem(SESSION_STORAGE_KEY.RELOAD_CONFIRM_MEMBER) == "reload")
      setconfirmStatusMessage(status.confirmMember);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSuperFundNameForAdobeAnalytics = (): string => {
    return superFundData.data?.superFundList?.find(
      item => item.superfundId.toString() === superfundId
    )?.superFundPrefix.toLowerCase() ?? "";
  };

  const onConfirmUserSubmit = () => {
    let predefinedSuperFundId: number | undefined

    AdobeAnalytics.PushClickEvent(getSuperFundNameForAdobeAnalytics(), confirmDetailsData.data?.continueButtonText, AdobeClickType.Button)

    window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_CONFIRM_MEMBER, "");

    setapiUpdateInProgress(true);
    setconfirmStatusMessage("");

    let requestData = {
      memberNumber: formData.memberNumber,
      dateOfBirth: formData.dateOfBirth,
      superFundId: predefinedSuperFundId ? predefinedSuperFundId : superfundId
    };

    axios.post(API.BASEURL, {
      query: CONFIRM_USER_DETAILS,
      variables: {
        confirmUserRequest: requestData
      }
    },
      {
        headers: {
          're-captcha': formData.reCaptchaToken
        }
      })
      .then(async (response) => {
        setapiUpdateInProgress(false);
        if (response.data.data?.confirmUser?.statusCode === ConfirmUserStatus.MEMBER_VALID) {
          setuserDetailsVerified(true);
          window.sessionStorage.clear();
        } else {
          setStatus('confirmMember', confirmDetailsData.data?.errorMessageContent.memberNotFoundMsg);
          window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_CONFIRM_MEMBER, "reload");
          window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_FACTORID, contextData.memberDetails?.factorId!);
          window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_STATETOKEN, contextData.memberDetails?.stateToken!);
          navigate(0);
        }
      });
  };


  const onMobileUpdate = () => {

    let requestData = {
      memberNumber: formData.memberNumber,
      dateOfBirth: formData.dateOfBirth,
      superFundId: superfundId,
      mobile: formData.mobileNumber
    };

    AdobeAnalytics.PushClickEvent(getSuperFundNameForAdobeAnalytics(), confirmDetailsData.data?.continueButtonText, AdobeClickType.Button)

    setapiUpdateInProgress(true);

    axios.post(API.BASEURL, {
      query: UPDATE_MOBILE_NUMBER,
      variables: {
        updateMobileRequest: requestData
      }
    },
      {
        headers: {
          're-captcha': formData.reCaptchaToken
        }
      })
      .then(async (response) => {
        setapiUpdateInProgress(false);
        if (response.data.data?.updateMobileNumber?.statusCode == UpdateMobileStatus.MOBILE_UPDATED) {
          updateContext(response.data.data?.updateMobileNumber?.response, contextData.memberDetails?.stateToken!);

          AccountApi.sendMfaCode(response.data.data?.updateMobileNumber?.response, contextData.memberDetails?.stateToken!)
            .then(response => response.json())
            .then(data => {
              console.log("sms sent")
            })
            .catch(httpError => {
              console.log('failed to send MFA sms', httpError);
            });

          navigate("/verifycode");
        }
        else
          setconfirmStatusMessage(confirmDetailsData.data?.errorMessageContent.mobileNumberUpdateFailed);
      });
  }

  const updateContext = (factorId, stateToken) => {

    let maskedMob = formData.mobileNumber.replace(/^0/, '+61')
    maskedMob = maskedMob.replace(maskedMob.substring(3, 9), '******')

    setUserContext({
      memberDetails: {
        stateToken: stateToken,
        mobileNumber: maskedMob,
        factorId: factorId
      }, claims: []
    });

  }

  const onSuperFundChange = event => {
    setSuperFund(event.target.value);
  }



  const isPageValidUpdateContext = () => {
    return ((contextData.memberDetails?.stateToken) ||
      (window.sessionStorage.getItem(SESSION_STORAGE_KEY.RELOAD_STATETOKEN)));
  }

  const RenderSuperfundChangeFields = () => {
    if (contextData.memberDetails?.policyManagerId && typeof contextData.memberDetails?.policyManagerId === 'number') {
      return null
    }

    return (<GelFormField
      label={confirmDetailsData.data?.superFundText}
    >
      <GelSelect
        name="superfund"
        onChange={onSuperFundChange}
        placeholder="Please select"
        options={superFundData.data?.superFundList?.filter(superFund => superFund.hideInDropDown === false)?.map((superFund) => (
          { label: superFund.superfundName, value: superFund.superfundId }
        ))}
        required
        requiredErrorMsg={confirmDetailsData.data?.errorMessageContent.superFundReqMsg}
      />
    </GelFormField>
    )
  }

  if (superFundData.isLoading || confirmDetailsData.isLoading)
    return <GelSpinner medium overlay />

  return (
    <GelContainerLite style={{
      paddingRight: sizeNone,
      paddingLeft: sizeNone,
      paddingBottom: sizeBaseUnit * 15,
      paddingTop: sizeBaseUnit * 15, overflow: "hidden"
    }}>
      <GelBoxLayout alignment="start" space={[1, 4]} gutter="none">
        {!userDetailsVerified &&
          <GelForm
            labelAtTop={true}
            width={isXsScreen(screen) ?
              '100%' : sizeBaseUnit * 110
            }
            {...!isXsScreen(screen) && {
              labelWidth: sizeBaseUnit * 50
            }}
            fieldGutter={sizeBaseUnit * 10}
            disableOnSubmit
            onSubmit={onConfirmUserSubmit}
            reset={resetForm}
            parseResponseBody={false}
            style={{
              paddingRight: sizeBaseUnit * 16,
              paddingLeft: sizeBaseUnit * 16
            }} >
            <GelRowLayout gutter="medium" style={{ paddingLeft: sizeBaseUnit * 3 }}>
              <GelHeading3>
                {confirmDetailsData.data?.headerText}
              </GelHeading3>
              <GelParagraph style={{ paddingTop: sizeBaseUnit * 2 }}>{confirmDetailsData.data?.subText}</GelParagraph>

              <RenderSuperfundChangeFields />

              <GelFormField label={confirmDetailsData.data?.memberNumberText} >
                <GelTextInput
                  name="memberNumber"
                  onChange={onFormDataChange}
                  required
                  value={formData.memberNumber}
                  requiredErrorMsg={confirmDetailsData.data?.errorMessageContent.memberNumberReqMsg} />
              </GelFormField>

              <GelFormField
                label={confirmDetailsData.data?.dateOfBirthText} >
                <GelStandardDateInput
                  name="dateOfBirth"
                  value={formData.dateOfBirth}
                  onChange={onFormDataChange}
                  required
                  lt="9999-12-31"
                  requiredErrorMsg={confirmDetailsData.data?.errorMessageContent.dobReqMsg}
                  errorMsg={{
                    required: `${confirmDetailsData.data?.errorMessageContent.dobReqMsg}`,
                    compare: `${confirmDetailsData.data?.errorMessageContent.dobFormatMsg}`,
                    format: `${confirmDetailsData.data?.errorMessageContent.dobFormatMsg}`
                  }}
                />
              </GelFormField>

              {isCaptchaEnabled &&
                <GelFormField
                  label={confirmDetailsData.data?.captchaText}>
                  <GelReCaptcha
                    name="reCaptchaToken"
                    sitekey={ReCaptchaSettings.ReCaptcha_SiteKey}
                    onChange={onFormDataChange}
                    errorMsg={{
                      required: `${confirmDetailsData.data?.errorMessageContent.captchaReqMsg}`,
                    }}
                  />
                </GelFormField>}

              {confirmStatusMessage &&
                <GelBoxLayout space={[1, 8]}>
                  <GelIcon color={themeColorIconDanger} name="AlertCircle" inline />
                  <GelLabel style={{ color: themeColorTextDanger }}>
                    {confirmStatusMessage}
                  </GelLabel>
                </GelBoxLayout>
              }
              {!apiUpdateInProgress &&
                <GelFormField width="auto" style={{ paddingTop: sizeBaseUnit * 4, paddingBottom: sizeBaseUnit * 4 }}>
                  <GelButton
                    name="" style={{
                      width: "100%"
                    }}
                    primary large submit
                  >
                    {confirmDetailsData.data?.continueButtonText}
                  </GelButton>
                </GelFormField>}
              {apiUpdateInProgress &&
                <GelSpinner medium overlay={true} />}
            </GelRowLayout>
          </GelForm>}
        {userDetailsVerified &&
          <GelForm
            labelAtTop={true}
            width={isXsScreen(screen) ?
              '100%' : sizeBaseUnit * 110
            }
            {...!isXsScreen(screen) && {
              labelWidth: sizeBaseUnit * 50
            }}
            fieldGutter={sizeBaseUnit * 10}
            disableOnSubmit
            onSubmit={onMobileUpdate}
            reset={resetForm}
            parseResponseBody={false}
            style={{
              paddingRight: sizeBaseUnit * 16,
              paddingLeft: sizeBaseUnit * 16
            }}>
            <GelRowLayout gutter="medium" style={{ paddingLeft: sizeBaseUnit * 3 }}>
              <GelHeading3>
                {confirmDetailsData.data?.updateMobileHeaderText}
              </GelHeading3>
              <GelParagraph>
                {confirmDetailsData.data?.updateMobileSubText}
              </GelParagraph>

              <GelFormField label={confirmDetailsData.data?.mobileNumberText} >
                <GelPhoneNumberInput
                  name="mobileNumber"
                  value={formData.mobileNumber}
                  onChange={onFormDataChange}
                  required
                  errorMsg={{
                    required: `${confirmDetailsData.data?.errorMessageContent.mobileNumberReqMsg}`,
                    length: `${confirmDetailsData.data?.errorMessageContent.mobileNumberLengthMsg}`,
                    prefix: `${confirmDetailsData.data?.errorMessageContent.mobileNumberPrefixMsg}`,
                  }}
                />
              </GelFormField>

              {!apiUpdateInProgress &&
                <GelFormField width="auto" style={{ paddingTop: sizeBaseUnit * 4 }}>
                  <GelButton
                    name="" style={{
                      width: "100%",
                    }}
                    primary large submit
                  >
                    {confirmDetailsData.data?.continueButtonText}
                  </GelButton>
                </GelFormField>
              }
              {apiUpdateInProgress &&
                <GelSpinner medium overlay={true} />}
            </GelRowLayout>
          </GelForm>}

        {isLargerThanSmScreen(screen) &&
          <FundBanner />}

      </GelBoxLayout>
    </GelContainerLite>
  );
};

export default ConfirmMember;
import { Outlet } from 'react-router-dom';
import { useCookies } from "react-cookie";
import React from 'react';
import ServerError from "../../pages/errorPages/ServerError";

export const NonProtectedRoute = () => {
  
  const [ssoCookies] = useCookies(['aid', 'rid']);
  
  if(!ssoCookies.aid && !ssoCookies.rid )
    return (<Outlet />)
  else
    return (<ServerError />);
}

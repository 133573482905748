import React, { memo, useState } from "react";
import { ReactNode } from "react";
import {
  GelContainerLite,
  GelRowLayout,
  GelLabel,
  GelForm,
  GelFormField,
  GelButton,
  GelIcon,
  GelBoxLayout,
  GelModal,
  GelSpinner
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { AdobeClickType } from "../../constants/constants";
import { useCookies } from "react-cookie";
import ClaimStatus from "./claimStatus";
import { AdobeAnalytics } from "../analytics/adobe-analytics";
import { useGetStatusDescriptionPageContent } from "../api/graphQLDataFetch";

interface ICardProps {
  title: string;
  subTitle?: string;
  icon?: ReactNode;
  buttonText?: string;
  children: ReactNode;
  showFooter: boolean;
  lightHeader?: boolean;
  claimAssesmentStatus?: ReactNode;
}

const Card: React.FC<ICardProps> = ({ title, subTitle, icon, buttonText, children, showFooter, lightHeader }) => {
  const { global: { sizeNone, sizeBase, sizeBaseUnit, sizeBaseX5, themeColorBackgroundDefault, fontFamilySans,
    sizeBaseX4, themeColorGrayT20, sizeBaseX2, themeColorGrayT05, themeColorBackgroundInverse, themeColorGrayT90 } } = getGelTokens();

  const { data, isLoading } = useGetStatusDescriptionPageContent()
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setClaimCookie] = useCookies(['selectedClaim']);
  const [{ tpid }] = useCookies(['tpid']);
  const [openModal, setOpenModal] = useState(false);


  const onSubmit = () => {
    setClaimCookie('selectedClaim', title);

    AdobeAnalytics.PushClickEvent(tpid, buttonText!, AdobeClickType.Button)
    navigate("/claimdetails", { state: { claimTypeName: title } });
  }

  const Header = styled.div(() => ({
    minHeight: sizeBaseUnit * 15,
    color: themeColorBackgroundDefault,
    fontSize: 10,
    fontFamily: fontFamilySans,
    padding: sizeBaseX4,
    borderRadius: `${sizeBaseUnit * 2}px ${sizeBaseUnit * 2}px 0px 0px`,
    margin: "0px"
  }));

  const Body = styled.div(() => ({
    color: themeColorBackgroundDefault,
    border: "0px solid",
    borderColor: themeColorGrayT20,
    borderRadius: `0px 0px ${sizeBaseUnit * 2}px ${sizeBaseUnit * 2}px`
  }));

  const Footer = styled.div(() => ({
    height: sizeBaseX2 * 10,
    color: themeColorBackgroundDefault,
    paddingTop: sizeBaseX2
  }));

  const onClose = () => {
    setOpenModal(false);
    AdobeAnalytics.PushClickEvent(tpid, data?.buttonText, AdobeClickType.Button)
  };

  const openModalWindow = () => {
    setOpenModal(true);
    const safeSubTitle = subTitle ?? ""
    AdobeAnalytics.PushClickEvent(tpid, safeSubTitle, AdobeClickType.Tooltip)
  }

  if (isLoading) {
    <GelSpinner medium overlay />;
  }

  return (
    <GelContainerLite gutter="medium" style={{
      backgroundColor: themeColorBackgroundDefault,
      border: "1px solid",
      borderColor: themeColorGrayT20,
      padding: "0px",
      borderRadius: sizeBaseUnit * 2,
    }}
    >
      <Header style={{
        backgroundColor: lightHeader ? themeColorGrayT05 :
          themeColorBackgroundInverse
      }}>
        <GelRowLayout gutter="small">

          <h1 style={{
            margin: "0px",
            color: lightHeader ? themeColorGrayT90 : themeColorBackgroundDefault
          }}>{title}</h1>

          <GelBoxLayout distribution="start" wrap={false} space="auto" gutter={sizeNone}>
            {icon &&
              <GelIcon style={{ paddingRight: sizeBaseX2 }}
                name={icon}
                width={sizeBaseX5}
                height={sizeBaseX5}
                color={lightHeader ? themeColorGrayT90 : themeColorBackgroundDefault}
                onClick={() => openModalWindow()} />
            }
            {subTitle &&
              <GelLabel style={{
                fontWeight: 10,
                display: "inline-block",
                color: lightHeader ? themeColorGrayT90 : themeColorBackgroundDefault
              }}>{subTitle}</GelLabel>
            }
          </GelBoxLayout>

          {openModal &&
            <GelModal
              width="sm"
              height={sizeBaseUnit * 170}
              open={openModal}
              onClose={() => setOpenModal(false)}
              sticky
              title={data?.pageTitle}
              action={
                <GelBoxLayout gap="small" space="auto">
                  <GelButton
                    secondary medium
                    width={sizeBaseUnit * 22}
                    onClick={onClose}
                  >
                    {data?.buttonText}
                  </GelButton>
                </GelBoxLayout>
              }
            >
              {
                data?.claimStatuses.map(claimStatus => (
                  <ClaimStatus statusType={claimStatus.StatusType} statusHeader={claimStatus.StatusHeader} statusDescription={claimStatus.StatusDescription} key={crypto.randomUUID()} />
                ))
              }
            </GelModal>
          }
        </GelRowLayout>
      </Header>

      <Body>{children}</Body>

      {showFooter &&
        <Footer style={{ padding: `${sizeBaseX5}px ${sizeBase * 2.5}px 0px ${sizeBase * 2.5}px`, boxSizing: 'content-box' }}>
          <GelForm
            labelAtTop={true}
            onSubmit={onSubmit}>
            <GelFormField width="auto">
              <GelButton style={{
                width: "100%",
              }}
                name=""
                primary large submit>
                {buttonText}
              </GelButton>
            </GelFormField>
          </GelForm>
        </Footer>}
    </GelContainerLite>
  );
}


export default memo(Card);
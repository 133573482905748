import React from 'react';
import {
    GelRowLayout,
    GelLabel
} from '@tal-gel/components';
import NotificationItem from './notification-item';
import { getGelTokens } from "@tal-gel/theming";
import { NotificationType } from '../../../constants/constants';

const NotificationList = ({ memberNumber, superfundId, requirements }) => {
    const { global: { sizeBaseUnit } } = getGelTokens()
    const visibleRequirements = requirements.filter(({ type, completedDate }) =>
        [NotificationType.ProceesedPayment, NotificationType.Activity, NotificationType.FuturePayment]
            .includes(type) || !completedDate);


    return (
        <div style={{ paddingTop: sizeBaseUnit * 2, paddingBottom: sizeBaseUnit }}>
            {visibleRequirements && visibleRequirements.length > 0 ? (
                <GelRowLayout>
                    {visibleRequirements.map((req) => (
                        <NotificationItem
                            key={req.id}
                            requirement={req}
                            memberNumber={memberNumber}
                            superfundId={superfundId}
                        />
                    ))}
                </GelRowLayout>
            ) : (
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    textAlign: 'center',
                    color: '#666',
                    padding: `${sizeBaseUnit}px 0`,
                    height: `50px`
                }}>
                    <GelLabel>You have no tasks to complete.</GelLabel>
                </div>
            )}
        </div>
    );
};

export default NotificationList;
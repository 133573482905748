/* eslint-disable @typescript-eslint/no-explicit-any */
import { ADOBE_ANALYTICS_SOURCE, HashMapping, SuperFundBrandingAdobe } from "../../constants/constants";

const getPageName = () => HashMapping[window.location.hash] ?? window.location.hash

export const AdobeAnalytics = {

    PushFormStartEvent(fund: string): void {

        if (ADOBE_ANALYTICS_SOURCE.indexOf("ADOBE_ANALYTICS_URL") > -1) {
            return;
        }

        try {
            (window as any).appEventData.push({
                "event": "formStart",
                "fund": SuperFundBrandingAdobe[fund] ?? "unknown fund",
                "featureName": "MMC",
                "pageUrl": window.location.href,
                "pageName": getPageName(),
            });
        }
        catch {
            console.log("Unable to publish event to adobe analytics.");
        }
    },

    PushFormStepEvent(fund: string): void {

        if (ADOBE_ANALYTICS_SOURCE.indexOf("ADOBE_ANALYTICS_URL") > -1) {
            return;
        }

        try {
            (window as any).appEventData.push({
                "event": "formStep",
                "fund": SuperFundBrandingAdobe[fund] ?? "unknown fund",
                "featureName": "MMC",
                "pageUrl": window.location.href,
                "pageName": getPageName(),
            });
        }
        catch {
            console.log("Unable to publish event to adobe analytics.");
        }
    },

    PushClickEvent(fund: string, clickText: string, clickType: string, popupName?: string): void {

        if (ADOBE_ANALYTICS_SOURCE.indexOf("ADOBE_ANALYTICS_URL") > -1) {
            return;
        }

        try {
            (window as any).appEventData.push({
                // The event field acts as an ID for an event, meaning that adobe-analytics knows which event the application is trying to register.
                "event": "clickEvent",
                "fund": SuperFundBrandingAdobe[fund] ?? "unknown fund",
                // The featureName field is a constant and should always be the same for all events for MMC.
                "featureName": "MMC",
                "pageUrl": window.location.href,
                "pageName": getPageName(),
                clickText,
                clickType,
                popupName
            });
        }
        catch {
            console.log("Unable to publish event to adobe analytics.");
        }
    },

    PushPageViewEvent(fund: string): void {

        if (ADOBE_ANALYTICS_SOURCE.indexOf("ADOBE_ANALYTICS_URL") > -1) {
            return;
        }

        try {
            (window as any).appEventData.push({
                "event": "pageView",
                "fund": SuperFundBrandingAdobe[fund] ?? "unknown fund",
                "featureName": "MMC",
                "pageUrl": window.location.href,
                "pageName": getPageName(),
            });
        }
        catch {
            console.log("Unable to publish event to adobe analytics.");
        }
    }

}

import React, { useContext } from "react";
import {
  GelScreenDetectorContext,
  GelContainerLite,
  GelParagraph,
  GelLink,
  GelColumnLayout
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { useCookies } from "react-cookie";
import styled from "@emotion/styled";
import { AdobeAnalytics } from "../common/analytics/adobe-analytics";
import { useGetFooterPageContent } from "../common/api/graphQLDataFetch";
import { AdobeClickType } from "../constants/constants";

const Footer = () => {
  const { global: { sizeBaseUnit, sizeBaseX4, themeColorBackgroundDefault, themeColorBackgroundDark, fontSizeBodySmall, fontSizeBodyLarge } } = getGelTokens();
  const [{ tpid }] = useCookies(['tpid']);
  const { screen, isLargerThanLgScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
  const { data, isFetched } = useGetFooterPageContent()


  const isFundPrivacyLinkVisible = () => {
    return isFetched && data?.fundPrivacyLinkText
      && data?.fundPrivacyLinkValue;
  }

  const Spacer = styled.div(() => ({
    paddingTop: sizeBaseX4
  }));

  const VerticalSeperator = styled.div(() => ({
    height: sizeBaseUnit * 6,
    width: sizeBaseUnit / 2,
    backgroundColor: themeColorBackgroundDefault,
    marginLeft: sizeBaseUnit * 3,
    marginRight: sizeBaseUnit * 3,
  }));

  const handleDisclaimerLinkClick = () => {
    AdobeAnalytics.PushClickEvent(tpid, data?.disclaimerLinkText, AdobeClickType.Link)
  }

  const handlePrivacyLinkClick = () => {
    AdobeAnalytics.PushClickEvent(tpid, data?.privacyLinkText, AdobeClickType.Link)
  }

  const handleFundPrivacyLinkTextClick = () => {
    AdobeAnalytics.PushClickEvent(tpid, data?.fundPrivacyLinkText, AdobeClickType.Link)
  }

  return (
    <GelContainerLite gutter="medium" style={{ backgroundColor: themeColorBackgroundDark, margin: 0, padding: 0 }}>
      <GelColumnLayout distribution={isLargerThanLgScreen(screen) ? "3/4 1/4" : "1"} gutter="none" verticalGutter="none"
        style={{ padding: isLargerThanLgScreen(screen) ? "40px 20px" : "21px 15px" }}>

        <GelContainerLite style={{ padding: 0 }}>
          <GelParagraph style={{ color: themeColorBackgroundDefault, fontSize: fontSizeBodySmall, fontWeight: "400" }}>
            <span dangerouslySetInnerHTML={{ __html: data?.footerText }} />
          </GelParagraph>

          {!isLargerThanLgScreen(screen) ?
            <Spacer /> : undefined
          }
        </GelContainerLite>

        <GelContainerLite style={{ display: "flex", justifyContent: !isLargerThanLgScreen(screen) ? "start" : "end", padding: 0 }}>
          <GelLink href={data?.disclaimerLinkValue} target="_blank" onClick={handleDisclaimerLinkClick}>
            <GelParagraph style={{ color: themeColorBackgroundDefault, padding: "0px 5px 0px 0px", fontSize: fontSizeBodyLarge, fontWeight: "400" }}>
              {data?.disclaimerLinkText}
            </GelParagraph>
          </GelLink>

          <VerticalSeperator />

          <GelLink href={data?.privacyLinkValue} target="_blank" onClick={handlePrivacyLinkClick}>
            <GelParagraph style={{ color: themeColorBackgroundDefault, padding: "0px 5px", fontSize: fontSizeBodyLarge, fontWeight: "400" }}>
              {data?.privacyLinkText}
            </GelParagraph>
          </GelLink>

          {isFundPrivacyLinkVisible() &&
            <>
              <VerticalSeperator />
              <GelLink href={data?.fundPrivacyLinkValue} target="_blank" onClick={handleFundPrivacyLinkTextClick}>
                <GelParagraph style={{ color: themeColorBackgroundDefault, padding: "0px 5px", fontSize: fontSizeBodyLarge, fontWeight: "400" }}>
                  {data?.fundPrivacyLinkText}
                </GelParagraph>
              </GelLink>
            </>
          }
        </GelContainerLite>

      </GelColumnLayout>
    </GelContainerLite>
  );
};

export default Footer;
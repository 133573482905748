import React, { useContext, useEffect, useState } from 'react';
import {
    GelForm, GelFormField, GelTextInput,
    useGelFormData,
    GelButton,
    GelBoxLayout,
    GelContainerLite,
    GelScreenDetectorContext,
    GelLabel,
    GelLink,
    GelIcon,
    GelRowLayout,
    GelHeading3,
    GelParagraph,
    GelSpinner
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { AccountApi } from '../../common/api/auth.provider';
import { UserContext } from '../../common/usercontext/user.context';
import { useOktaAuth } from '@okta/okta-react';
import FundBanner from "../../components/fundBanner";
import { AdobeClickType, OKTA_ERROR_ACCOUNT_LOCKED, OktaRecentSmsErrorCode } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';
import SESSION_STORAGE_KEY from '../../constants/storage.constant';
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';
import { useCookies } from 'react-cookie';
import { useGetTwoFactorPageContentData } from '../../common/api/graphQLDataFetch';

const TwoFactorAuth = () => {
    const { global: { sizeNone, sizeBaseUnit, themeColorTextDanger, sizeBaseX2 } } = getGelTokens()

    const { data, isFetched } = useGetTwoFactorPageContentData()

    const { oktaAuth } = useOktaAuth();
    const { isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [mfaStatusMessage, setMfaStatusMessage] = useState("");
    const [isAccountLocked, setAccountLocked] = useState(false);
    const [{ tpid }] = useCookies(["tpid"])

    const navigate = useNavigate();

    useEffect(() => {
        AdobeAnalytics.PushPageViewEvent(tpid)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {
        formData,
        onFormDataChange,
        onFormDataReset,
        resetForm
    } = useGelFormData({
        verificationCode: '',
        memberNumber: ''
    });

    const { contextData } = useContext(UserContext)

    const onSubmit = () => {
        AdobeAnalytics.PushClickEvent(tpid, data?.verifyButtonText, AdobeClickType.Button)

        AccountApi.verifyMfaCode(contextData.memberDetails?.factorId!, contextData.memberDetails?.stateToken!, formData.verificationCode)
            .then(response => response.json())
            .then(data => {
                console.log("okta response ->", data)
                if (data?.status === "SUCCESS") {
                    if (contextData.memberDetails?.oktaEmail) {
                        window.sessionStorage.setItem(SESSION_STORAGE_KEY.SEND_ACTIVATION_EMAIL, contextData.memberDetails?.oktaEmail);
                    }
                    oktaAuth.signInWithRedirect({ sessionToken: data.sessionToken });
                }
                else {
                    if (data?.errorLink == OKTA_ERROR_ACCOUNT_LOCKED) {
                        setAccountLocked(true);
                        setMfaStatusMessage('Invalid 6-digit code. Your account has been locked. Please reach out to your Claims Consultant');
                    }
                    else {
                        setMfaStatusMessage(data?.errorMessageContent.verificationFailedMsg);
                    }
                }
            }).catch(httpError => {
                console.log('MFA verification failed', httpError);
            });
    };

    const onResend = () => {

        AdobeAnalytics.PushClickEvent(tpid, data?.resendCodeText, AdobeClickType.Link)



        AccountApi.sendMfaCode(contextData.memberDetails?.factorId!, contextData.memberDetails?.stateToken!)
            .then(response => response.json())
            .then(data => {
                if (data?.errorCode) {
                    if (data.errorCode == OktaRecentSmsErrorCode)
                        setMfaStatusMessage(data?.errorMessageContent.smsSentRecentlyMsg);
                    else
                        setMfaStatusMessage(data?.errorMessageContent.sendSmsFailed);
                }
                else
                    setMfaStatusMessage('');

            })
            .catch(httpError => {
                console.log('failed to send MFA sms', httpError);
                setMfaStatusMessage(data?.errorMessageContent.sendSmsFailed);
            });
    }

    const navigateToMobileUpdate = () => {

        AdobeAnalytics.PushClickEvent(tpid, data?.resetMobileLink, AdobeClickType.Link)

        navigate("/confirmmember");
    }

    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;

    if (!contextData.memberDetails?.stateToken || !isFetched)
        return (<GelSpinner medium overlay />)

    return (
        <GelContainerLite style={{
            paddingRight: sizeNone,
            paddingLeft: sizeNone,
            paddingBottom: sizeBaseUnit * 15,
            paddingTop: sizeBaseUnit * 15, overflow: "hidden"
        }}>
            <GelBoxLayout alignment="start" space={[1, 4]} gutter="none">
                <GelForm
                    labelAtTop={true}
                    width={isXsScreen(screen) ?
                        '100%' : sizeBaseUnit * 110
                    }
                    {...!isXsScreen(screen) && {
                        labelWidth: sizeBaseUnit * 50
                    }}
                    fieldGutter={sizeBaseUnit * 10}
                    disableOnSubmit
                    onSubmit={onSubmit}
                    reset={resetForm}
                    onReset={onFormDataReset}
                    parseResponseBody={false}
                    style={{
                        paddingRight: sizeBaseUnit * 16,
                        paddingLeft: sizeBaseUnit * 16
                    }}
                >
                    <GelRowLayout gutter="medium" style={{ paddingLeft: sizeBaseUnit * 3 }}>
                        <GelHeading3>
                            {data?.headerText} {contextData.memberDetails?.mobileNumber}
                        </GelHeading3>

                        <GelParagraph style={{ paddingTop: sizeBaseUnit * 2 }}>{data?.subText}</GelParagraph>
                        <div style={{ paddingBottom: sizeBaseUnit * 2 }}>
                            <GelLabel style={{
                                fontWeight: 100
                            }}>
                                {data?.headerSubText}
                            </GelLabel>
                        </div>
                        <GelFormField
                            label={data?.verificationCodeText}
                        >
                            <GelTextInput
                                name="verificationCode"
                                onChange={onFormDataChange}
                                value={formData.verificationCode}
                                required
                                requiredErrorMsg={data?.errorMessageContent.verifyCodeReqMsg}
                            />

                        </GelFormField>

                        {mfaStatusMessage &&
                            <GelLabel style={{
                                color: themeColorTextDanger
                            }}>
                                <GelIcon color={themeColorTextDanger} name="AlertCircle" inline />{mfaStatusMessage}
                            </GelLabel>}

                        <GelFormField style={{ paddingTop: sizeBaseUnit * 4 }}>
                            <GelButton
                                name="" style={{
                                    width: "100%",
                                }}
                                primary large submit
                                disabled={isAccountLocked}
                            >
                                {data?.verifyButtonText}
                            </GelButton>
                        </GelFormField>

                        <div style={{ paddingTop: sizeBaseUnit * 4 }}>
                            <GelLabel style={{
                                fontWeight: 100,
                                paddingRight: sizeBaseX2
                            }}>
                                {data?.didNotReceiveCodeText}
                                <GelLink onClick={onResend}>  {data?.resendCodeText}</GelLink>
                            </GelLabel>
                        </div>

                        <div style={{ paddingBottom: sizeBaseUnit * 4 }}>
                            <GelLabel style={{
                                fontWeight: 100,
                                paddingRight: sizeBaseX2
                            }}>
                                {data?.notYourMobileText}
                                <GelLink onClick={navigateToMobileUpdate}>  {data?.resetMobileLink}</GelLink>
                            </GelLabel>
                        </div>
                    </GelRowLayout>
                </GelForm>
                {isLargerThanSmScreen(screen) &&
                    <FundBanner />
                }
            </GelBoxLayout>
        </GelContainerLite>

    )
}

export default TwoFactorAuth;
import { useOktaAuth } from '@okta/okta-react';
import { Outlet } from 'react-router-dom';
import UnAuthorized from '../../components/unauthorized';
import { useCookies } from "react-cookie";

export const ProtectedRoute = () => {

  const { authState } = useOktaAuth();
  const [{ aid, rid }] = useCookies(['aid', 'rid']);

  if ((authState && authState.isAuthenticated) ||
    (aid && rid))
    return (<Outlet />)
  else
    return (<UnAuthorized />);

}

import {
    GelForm,
    GelButton,
    GelContainerLite
} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import React, { useEffect, useState } from "react";
import { Activities, MemberClaims, AssociatedDocuments } from "../../../common/models/memberclaims";
import { FormatHelper } from '../../../common/formatHelper.common';
import { ACTIVITY_TYPE, AdobeClickType, CLAIM_STATUS_TYPE } from "../../../constants/constants";
import styled from "@emotion/styled";
import ClaimHistoryItem from "./claimHistory-item";
import { AdobeAnalytics } from "../../../common/analytics/adobe-analytics";
import { useCookies } from "react-cookie";
import { useGetClaimDetailsContent } from "../../../common/api/graphQLDataFetch";

interface ClaimHistoryProps {
    claimDetails: MemberClaims
}

const { global: { sizeBaseUnit, themeColorBorderDefault, themeColorGrayT05, sizeBaseX2, themeColorBackgroundDefault } } = getGelTokens();


const Footer = styled.div(() => ({
    height: sizeBaseX2 * 10,
    color: themeColorBackgroundDefault,
    paddingTop: sizeBaseX2
}));

const ClaimHistory = (props: ClaimHistoryProps) => {
    const [{ tpid }] = useCookies(["tpid"])

    const {
        claimDetails,
    } = props;

    const [rowCount, setRowCount] = useState(0);
    const [claimActivityDetailsPage, setclaimActivityDetailsPage] = useState(new Array<Activities>());
    const [claimActivityDetailsFull, setclaimActivityDetailsFull] = useState(new Array<Activities>());

    const { data } = useGetClaimDetailsContent()


    const LoadNextPage = () => {

        if (rowCount <= claimActivityDetailsFull.length) {
            setRowCount(rowCount + 5);
            setclaimActivityDetailsPage(claimActivityDetailsFull.slice(0, rowCount));
        }

        AdobeAnalytics.PushClickEvent(tpid, data?.historyLoadMoreButtonText, AdobeClickType.Button)
    }

    const setClaimHistoryRecords = () => {
        let claimActivityDetails = new Array<Activities>();

        claimDetails?.activities?.filter(ac => !ac.hideActivity).forEach((activity) => {

            if (activity.type === ACTIVITY_TYPE.REQUIREMENT) {

                /* logic for creating a duplicate activity in runtime for those requiments which are completed. ex: required document uploaded. */
                const activityDetails: Activities = {
                    claimPaymentId: 0,
                    hideActivity: false,
                    title: data?.requirementRaisedText,
                    message: FormatHelper.stringFormat(data?.requirementRaisedMessage, activity.friendlyText),
                    description: activity.description,
                    type: activity.type,
                    date: activity.date,
                    friendlyText: activity.friendlyText,
                    documents: new Array<AssociatedDocuments>()
                };

                var matchedRequirement = claimDetails.requirements.find(f => f.description === activity.friendlyText && f.completedDate);
                if (matchedRequirement) {
                    activity.title = data?.requirementCompletedText;
                    activity.message = FormatHelper.stringFormat(data?.requirementCompletedMessage,
                        activity.friendlyText);
                    activity.documents = new Array<AssociatedDocuments>();
                    matchedRequirement?.associatedDocuments?.map((document) => (
                        activity.documents.push(document)
                    ))

                    activityDetails.date = matchedRequirement?.requestedDate!;
                    claimActivityDetails.push(activity);
                }
                claimActivityDetails.push(activityDetails);
            }
            else if (activity.type === ACTIVITY_TYPE.PAYMENT) {
                activity.hideActivity = false;
                activity.title = data?.paymentText;
                activity.message = FormatHelper.stringFormat(data?.paymentMessage,
                    activity.friendlyText, FormatHelper.formatDate(activity.date));
                claimActivityDetails.push(activity);
            }
            else if (activity.type === ACTIVITY_TYPE.ACTIVITY) {
                if (activity.title.toLocaleLowerCase().includes(CLAIM_STATUS_TYPE.RTS.toLocaleLowerCase())) {
                    activity.message = `${data?.claimHistoryRefferedToDescription} ${FormatHelper.formatDate(activity.date)}`;
                }
                else if (activity.title.toLocaleLowerCase().includes(CLAIM_STATUS_TYPE.CS.toLocaleLowerCase())) {
                    activity.message = `${data?.claimHistoryStartedDescription} ${FormatHelper.formatDate(activity.date)}`;
                }
                else if (activity.title.toLocaleLowerCase().includes(CLAIM_STATUS_TYPE.APPROVED.toLocaleLowerCase())) {
                    activity.message = `${data?.claimHistoryApprovedDescription} ${FormatHelper.formatDate(activity.date)}`;
                }

                activity.hideActivity = false;
                activity.title = activity.title;
                claimActivityDetails.push(activity);
            }
        })

        claimActivityDetails.sort((a, b) => FormatHelper.toDate(b.date).getTime() - FormatHelper.toDate(a.date).getTime());
        setclaimActivityDetailsFull(claimActivityDetails);
    }

    useEffect(() => {
        setClaimHistoryRecords();
        if (rowCount === 0)
            setRowCount(5);

        setclaimActivityDetailsPage(claimActivityDetailsFull.slice(0, rowCount));
    }, [rowCount]);

    return (
        <div style={{ paddingTop: sizeBaseUnit * 4, paddingBottom: sizeBaseUnit * 5 }}>
            <GelContainerLite style={{
                borderBottom: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                borderTop: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                borderLeft: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                borderRight: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                width: "auto",
                cursor: "pointer",
                marginLeft: sizeBaseUnit * 6,
                marginRight: sizeBaseUnit * 6
            }}>
                <div style={{ maxHeight: "500px", overflowY: "auto", overflowX: "hidden" }}>
                    {claimActivityDetailsPage.map((activity) =>
                        <>
                            <ClaimHistoryItem key={Math.random()} activity={activity} marginNeeded={rowCount > 5} />
                            <div style={{ border: `${sizeBaseUnit / 4}px solid ${themeColorGrayT05}` }} key={Math.random()} />
                        </>
                    )}
                </div>

                {rowCount < claimActivityDetailsFull.length &&
                    <Footer>
                        <GelForm onSubmit={LoadNextPage}>
                            <GelButton style={{ width: "100%" }} primary large submit>
                                {data?.historyLoadMoreButtonText}
                            </GelButton>
                        </GelForm>
                    </Footer>}
            </GelContainerLite>
        </div>
    );
};

export default ClaimHistory;

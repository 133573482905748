import React, { useState } from 'react';
import {
    GelBoxLayout,
    GelRowLayout,
    GelIcon,
    GelLabel
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { StyledListHeader } from "../nav-menu/styles/nav.styles";
import { NavProps } from "./nav-props";

// TODO: Investigate and address the unused component.
// Determine if the component is truly unnecessary and can be safely removed.
// If the component is intended to be used, identify and fix the issue preventing its triggering or usage.
const MiniTopNavMob = ({ onMenuHideClick, activeMenuItem }: NavProps) => {
    const { global: { sizeBaseUnit, themeColorWhite, fontWeightBold } } = getGelTokens();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [selectedMenuItem, _] = useState(activeMenuItem);

    return (
        <GelRowLayout >
            <GelBoxLayout>
                <StyledListHeader>
                    <GelLabel style={{
                        color: themeColorWhite,
                        fontWeight: fontWeightBold
                    }}>{selectedMenuItem ? selectedMenuItem.text.toUpperCase() : "NEED HELP"}</GelLabel>
                    <GelIcon
                        name="MinimalDown"
                        width={sizeBaseUnit * 6}
                        style={{ float: "right" }}
                        color={themeColorWhite}
                        onClick={() => onMenuHideClick()}
                    />
                </StyledListHeader>
            </GelBoxLayout>
        </GelRowLayout>
    );
};

export default MiniTopNavMob;
import React, { useContext, useEffect } from 'react';
import {
    GelScreenDetectorContext,
    GelRowLayout,
    GelContainerLite,
    GelBoxLayout,
    GelParagraph,
    GelHeading2,
    GelSpinner
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import styled from '@emotion/styled';
import { UserContext } from '../../common/usercontext/user.context';
import Card from '../../common/components/card';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import ResponsiveContainer from "../../components/responsive.container";
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';
import { useGetYourDetailsPageContent } from '../../common/api/graphQLDataFetch';

const YourDetailsContainer = () => {
    const { global: { sizeBaseX8, sizeBaseX15, themeColorBackgroundLight, sizeBaseX10, sizeNone, sizeBaseX3, sizeBaseX5, sizeBaseX4 } } = getGelTokens();

    const { data, isFetched } = useGetYourDetailsPageContent()

    const navigate = useNavigate();
    const { contextData } = useContext(UserContext)
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [{ tpid }] = useCookies(['tpid']);

    const yourdetails = contextData.yourDetails;

    if (!yourdetails) {
        navigate("/error");
    }

    if (contextData.memberDetails?.displayLegalDisclosure)
        navigate("/yourDeclaration");

    useEffect(() => {
        AdobeAnalytics.PushFormStepEvent(tpid)
        AdobeAnalytics.PushPageViewEvent(tpid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isFetched)
        return <GelSpinner medium overlay />

    const Spacer = styled.div(() => ({
        paddingTop: sizeBaseX5
    }));

    const PageHeader = styled.div(() => ({
        padding: isXsScreen(screen) ? `${sizeBaseX15}px ${sizeBaseX5}px` : `${sizeBaseX15}px ${sizeBaseX10}px`,
        backgroundColor: themeColorBackgroundLight
    }));

    return (
        <GelContainerLite style={{ padding: sizeNone, overflow: "hidden" }}>
            <PageHeader >
                <GelHeading2>{data?.headerText}</GelHeading2>
                <GelParagraph style={{ paddingTop: sizeBaseX3 }}>{data?.headerDescription}</GelParagraph>
            </PageHeader>

            <ResponsiveContainer>
                <GelRowLayout style={{ padding: isXsScreen(screen) ? `${sizeBaseX10}px ${sizeBaseX5}px` : `${sizeBaseX5}px ${sizeBaseX10}px` }}>
                    <Card title={data?.title}
                        subTitle={data?.subtitle}
                        showFooter={false}>
                        <GelContainerLite style={{ padding: `${sizeBaseX8}px ${sizeBaseX4}px` }}>
                            <GelBoxLayout>
                                <GelParagraph id={"insuredName"}>{data?.insuredNameText}</GelParagraph>
                                <GelParagraph id={"insuredNameValue"} style={{ textAlign: "right" }}>{yourdetails?.fullName}</GelParagraph>
                            </GelBoxLayout>

                            <Spacer />

                            <GelBoxLayout>
                                <GelParagraph id={"dateofbirth"}>{data?.dateofbirth}</GelParagraph>
                                <GelParagraph id={"dateofbirthValue"} style={{ textAlign: "right" }}>{yourdetails?.dateOfBirth}</GelParagraph>
                            </GelBoxLayout>

                            <Spacer />
                        </GelContainerLite>
                    </Card>

                    <Card title={data?.contactTitle}
                        subTitle={data?.contactSubtext}
                        showFooter={false}>
                        <GelContainerLite style={{ padding: `${sizeBaseX8}px ${sizeBaseX4}px` }}>
                            <GelBoxLayout>
                                <GelParagraph id={"contactNumber"}>{data?.contactNumber}</GelParagraph>
                                <GelParagraph id={"contactNumberValue"} style={{ textAlign: "right" }}>{yourdetails?.contactNumber}</GelParagraph>
                            </GelBoxLayout>

                            <Spacer />

                            <GelBoxLayout>
                                <GelParagraph id={"email"}>{data?.email}</GelParagraph>
                                <GelParagraph id={"emailValue"} style={{ textAlign: "right" }}>{yourdetails?.email}</GelParagraph>
                            </GelBoxLayout>

                            <Spacer />

                            <GelBoxLayout>
                                <GelParagraph id={"address"}>{data?.address}</GelParagraph>
                                <GelParagraph id={"addressValue"} style={{ textAlign: "right" }}>{yourdetails?.address}</GelParagraph>
                            </GelBoxLayout>

                            <Spacer />
                        </GelContainerLite>
                    </Card>

                    {/* Temporarily disabling Notification Preference UI for 20th Jan Team Super rebranding. 
                        <NotificationPreferences contentfulData={data}/>
                        */}
                </GelRowLayout>
            </ResponsiveContainer>

        </GelContainerLite>
    );
};

export default YourDetailsContainer;
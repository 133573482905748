import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { AdobeClickType, API, ReCaptchaSettings } from '../../constants/constants';
import {
    GelForm, GelFormField,
    GelIcon,
    useGelFormData,
    GelButton,
    GelBoxLayout,
    GelScreenDetectorContext,
    GelContainerLite,
    GelLabel,
    GelLink,
    GelSelect,
    GelRowLayout,
    GelEmailInput,
    GelReCaptcha,
    GelSpinner,
    GelHeading3,
    GelParagraph
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { useNavigate } from 'react-router';
import FundBanner from "../../components/fundBanner";
import { PASSWORD_RESET_MAIL } from '../../graphql/queries/graphql-mutations';
import SESSION_STORAGE_KEY from '../../constants/storage.constant';
import { useCookies } from 'react-cookie';
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';
import { useGetForgotPasswordContentData, useGetSuperfundData } from '../../common/api/graphQLDataFetch';

const ForgotPassword = () => {
    const { global: { sizeNone, sizeBaseUnit, sizeBaseX2, themeColorTextDanger, themeColorIconDanger, themeColorTextSuccess, themeColorIconSuccess } } = getGelTokens()

    const [superfundId, setSuperFund] = useState(null);
    const { screen, isXsScreen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const navigate = useNavigate();
    const [resetStatusMessage, setresetStatusMessage] = useState("");
    const [resetSuccessMessage, setresetSuccessMessage] = useState("");
    const [resetInProgress, setresetInProgress] = useState(false);
    const [status, setStatus] = useCookies(['forgotPwdStatus', 'tpid']);
    const [{ tpid }] = useCookies(["tpid"])

    const forgotPasswordPageData = useGetForgotPasswordContentData()
    const superFundData = useGetSuperfundData()

    let isCaptchaEnabled = ReCaptchaSettings.ReCaptcha_Enabled == "1";

    const {
        formData,
        onFormDataChange,
    } = useGelFormData({
        reCaptchaToken: null,
        superFund: '',
        email: ''
    });

    useEffect(() => {
        const fundName = tpid ?? ""
        AdobeAnalytics.PushPageViewEvent(fundName)

        if (window.sessionStorage.getItem(SESSION_STORAGE_KEY.RELOAD_FORGOT_PWD) == "successReload") {
            setresetSuccessMessage(status.forgotPwdStatus);
            window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_FORGOT_PWD, "");
        }

        if (window.sessionStorage.getItem(SESSION_STORAGE_KEY.RELOAD_FORGOT_PWD) == "errorReload") {
            setresetStatusMessage(status.forgotPwdStatus);
            window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_FORGOT_PWD, "");
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getSuperFundNameForAdobeAnalytics = (): string => {
        return superFundData.data?.superFundList?.find(
            item => item.superfundId.toString() === superfundId
        )?.superFundPrefix.toLowerCase() ?? "";
    };

    const onSubmit = () => {

        AdobeAnalytics.PushClickEvent(getSuperFundNameForAdobeAnalytics(), forgotPasswordPageData.data?.continueButtonText, AdobeClickType.Button)



        window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_FORGOT_PWD, "");

        let requestData = {
            email: formData.email,
            superFundId: superfundId
        };

        setresetInProgress(true);
        setresetStatusMessage("");

        axios.post(API.BASEURL, {
            query: PASSWORD_RESET_MAIL,
            variables: {
                forgotPasswordRequest: requestData
            }
        },
            {
                headers: {
                    're-captcha': formData.reCaptchaToken
                }
            })
            .then(async (response) => {
                setresetInProgress(false);
                if (response.data.data.sendPasswordResetMail) {
                    setStatus('forgotPwdStatus', forgotPasswordPageData.data?.errorMessageContent.resetPasswordSuccessMsg);
                    window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_FORGOT_PWD, "successReload");
                    navigate(0);
                }
                else {
                    setStatus('forgotPwdStatus', forgotPasswordPageData.data?.errorMessageContent.resetFailedMsg);
                    window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_FORGOT_PWD, "errorReload");
                    navigate(0);
                }
            })
    };

    const navigateToSignIn = () => {
        AdobeAnalytics.PushClickEvent(getSuperFundNameForAdobeAnalytics(), forgotPasswordPageData.data?.signInLink, AdobeClickType.Link)

        window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_SIGNUP, "");
        setStatus('forgotPwdStatus', "");
        navigate("/login");
    };

    const onSuperFundChange = event => {
        setSuperFund(event.target.value);
    }


    if (forgotPasswordPageData.isLoading || superFundData.isLoading)
        return <GelSpinner medium overlay />

    return (
        <GelContainerLite style={{
            paddingRight: sizeNone,
            paddingLeft: sizeNone,
            paddingBottom: sizeBaseUnit * 15,
            paddingTop: sizeBaseUnit * 15, overflow: "hidden"
        }}>
            <GelBoxLayout alignment="start" space={[1, 4]} gutter="none">
                <GelForm
                    labelAtTop={true}
                    width={isXsScreen(screen) ?
                        '100%' : sizeBaseUnit * 110
                    }
                    {...!isXsScreen(screen) && {
                        labelWidth: sizeBaseUnit * 50
                    }}
                    fieldGutter={sizeBaseUnit * 10}
                    disableOnSubmit
                    onSubmit={onSubmit}
                    parseResponseBody={false}
                    style={{
                        paddingRight: sizeBaseUnit * 16,
                        paddingLeft: sizeBaseUnit * 16
                    }}>
                    <GelRowLayout gutter="medium" style={{ paddingLeft: sizeBaseUnit * 3 }}>
                        <GelHeading3>
                            {forgotPasswordPageData.data?.headerText}
                        </GelHeading3>

                        <GelParagraph style={{ paddingTop: sizeBaseUnit * 2 }}>
                            {forgotPasswordPageData.data?.subText}
                        </GelParagraph>

                        <GelFormField
                            label={forgotPasswordPageData.data?.superFundText}
                        >
                            <GelSelect
                                name="superfund"
                                onChange={onSuperFundChange}
                                placeholder="Please select"
                                options={superFundData.data?.superFundList?.filter(superFund => superFund.hideInDropDown == false)?.map((superFund) => (
                                    { label: superFund.superfundName, value: superFund.superfundId }
                                ))}
                                required
                                requiredErrorMsg={forgotPasswordPageData.data?.errorMessageContent.superFundReqMsg}
                            />
                        </GelFormField>

                        <GelFormField label={forgotPasswordPageData.data?.emailText} >
                            <GelEmailInput
                                name="email"
                                onChange={onFormDataChange}
                                required
                                value={formData.email}
                                requiredErrorMsg={forgotPasswordPageData.data?.errorMessageContent.emailReqMsg}
                                formatErrorMsg={forgotPasswordPageData.data?.errorMessageContent.emailFormatMsg}
                            />
                        </GelFormField>

                        {isCaptchaEnabled &&
                            <GelFormField
                                label={forgotPasswordPageData.data?.captchaText}>
                                <GelReCaptcha
                                    name="reCaptchaToken"
                                    sitekey={ReCaptchaSettings.ReCaptcha_SiteKey}
                                    onChange={onFormDataChange}
                                    errorMsg={{
                                        required: `${forgotPasswordPageData.data?.errorMessageContent.captchaReqMsg}`,
                                    }}
                                />
                            </GelFormField>}

                        {resetStatusMessage &&
                            <GelBoxLayout space={[1, 8]}>
                                <GelIcon color={themeColorIconDanger} name="AlertCircle" inline />
                                <GelLabel style={{ color: themeColorTextDanger }}>
                                    {resetStatusMessage}
                                </GelLabel>
                            </GelBoxLayout>
                        }

                        {resetSuccessMessage &&
                            <GelBoxLayout space={[1, 8]}>
                                <GelIcon color={themeColorIconSuccess} name="CheckSmall" inline />
                                <GelLabel style={{ color: themeColorTextSuccess }}>
                                    {resetSuccessMessage}
                                </GelLabel>
                            </GelBoxLayout>
                        }

                        {!resetInProgress &&
                            <GelFormField width="auto" style={{ paddingTop: sizeBaseUnit * 3 }}>
                                <GelButton
                                    name="" style={{
                                        width: "100%",
                                    }}
                                    primary large submit
                                >
                                    {forgotPasswordPageData.data?.continueButtonText}
                                </GelButton>
                            </GelFormField>}
                        {resetInProgress &&
                            <GelSpinner medium overlay={true} />}
                        <GelLabel style={{
                            fontWeight: 100,
                            paddingRight: sizeBaseX2
                        }}> {forgotPasswordPageData.data?.signInText}
                            <GelLink onClick={navigateToSignIn}>  {forgotPasswordPageData.data?.signInLink}</GelLink>
                        </GelLabel>
                    </GelRowLayout>
                </GelForm>
                {isLargerThanSmScreen(screen) &&
                    <FundBanner />}
            </GelBoxLayout>
        </GelContainerLite>
    );
};

export default ForgotPassword;
import {
    GelRow,
    GelCol,
    GelLabel,
    GelParagraph,
    GelScreenDetectorContext} from "@tal-gel/components";
import { getGelTokens } from "@tal-gel/theming";
import React, { useContext } from "react";
import { FormatHelper } from "../../../common/formatHelper.common";
import DocumentDownload from "../documentDownload/documentDownload";

const ClaimHistoryItem = (props) => {
    const {global: { sizeBaseUnit, sizeBaseX1, sizeBaseX2 }} = getGelTokens();
    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;

    return (
        <div>
            <GelRow style={{
                        marginLeft: -sizeBaseUnit * 4,
                        marginRight: -sizeBaseUnit * 4,
                        display: 'flex',
                        flexDirection: isXsScreen(screen) ? 'column' : 'row',
                    }}>
                        <GelCol style={{
                            paddingTop: sizeBaseX2
                        }}>
                            <GelLabel style={{ fontWeight: 500 }}>
                                {props.activity.title}
                            </GelLabel>
                        </GelCol>

                        <GelCol style={{
                            paddingTop: isXsScreen(screen) ? 0 : sizeBaseX2,
                            marginRight: props.marginNeeded ?  sizeBaseX2 : 0,
                            textAlign: isXsScreen(screen) ? 'left' : 'right',
                        }}>
                            <GelParagraph>
                                {FormatHelper.daysDifference(props.activity.date)}
                            </GelParagraph>
                        </GelCol>

                    </GelRow>
                        <GelRow style={{
                            marginLeft: -sizeBaseUnit * 4,
                            marginRight: -sizeBaseUnit * 4,
                        }}>
                            <GelCol style={{
                                paddingTop: sizeBaseX1
                            }}>
                                <GelParagraph>
                                    {props.activity.message}
                                </GelParagraph>
                                
                                <div style={{ paddingBottom: sizeBaseX2, }}>
                                    {props.activity?.documents?.map((document) => (
                                        <GelRow key={Math.random()} 
                                            style={{
                                            marginLeft: sizeBaseUnit,
                                            marginRight: sizeBaseUnit,
                                        }}>
                                            <DocumentDownload documentIdentifier={document.sharePointDocumentIdentifier}
                                                displayName={document.displayName} />
                                        </GelRow>
                                    ))}
                                </div>
                            </GelCol>
                        </GelRow>
                     
        </div>
    );
};

export default ClaimHistoryItem;
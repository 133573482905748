import React, { memo, useContext } from "react";
import {
    GelRowLayout,
    GelParagraph,
    GelHeading3,
    GelHeading5,
    GelContainerLite,
    GelLink,
    GelColumnLayout,
    GelScreenDetectorContext
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import styled from "@emotion/styled";
import { AdobeAnalytics } from "../../common/analytics/adobe-analytics";
import { AdobeClickType } from "../../constants/constants";
import { useCookies } from "react-cookie";
import { useGetHealthAndSupportPageContent } from "../../common/api/graphQLDataFetch";


const SupportInRecovery: React.FC = () => {
    const { global: { sizeBaseX10, sizeNone, sizeBaseX9, themeColorBackgroundDefault, sizeBaseX4, sizeBaseX2 } } = getGelTokens();

    const { screen, isLargerThanLgScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [{ tpid }] = useCookies(["tpid"]);

    const { data } = useGetHealthAndSupportPageContent()

    const ImageContainer = styled.div`
        position: relative;
        text-align: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 168px;
        width: 100%;
        background-position: center;
        border-radius: ${sizeBaseX2}px;
    `

    const onLinkClick = (linkText) => {
        AdobeAnalytics.PushClickEvent(tpid, linkText, AdobeClickType.Link)
    }

    return (
        <GelContainerLite style={{ padding: `${sizeBaseX10}px ${sizeNone}px` }}>
            <GelRowLayout style={{ paddingBottom: sizeBaseX9 }}>
                <GelHeading3>{data?.supportInRecoveryHeader}</GelHeading3>
            </GelRowLayout>

            <GelColumnLayout distribution={!isLargerThanLgScreen(screen) ? "1" : "1/2 1/2"}>

                <GelContainerLite style={{ padding: "initial" }}>
                    <GelLink href={data?.supportServicesLink} target="_blank"
                        onClick={() => onLinkClick(data?.supportServicesLinkText)}>
                        <ImageContainer id="supportService" style={{ backgroundImage: `url(${data?.supportServicesImage.url})` }}>

                            <GelContainerLite style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                <GelHeading5 style={{ color: themeColorBackgroundDefault }}>{data?.supportServicesImage.title}</GelHeading5>
                                <GelParagraph style={{ color: themeColorBackgroundDefault }}>{data?.supportServicesImage.description}</GelParagraph>
                            </GelContainerLite>
                        </ImageContainer>

                    </GelLink>
                    <GelParagraph style={{ padding: `${sizeBaseX4}px ${sizeNone}px` }}> {data?.supportServicesSubtext}</GelParagraph>

                    <GelLink href={data?.supportServicesLink} target="_blank"
                        onClick={() => onLinkClick(data?.supportServicesLinkText)}>
                        {data?.supportServicesLinkText}
                    </GelLink>
                </GelContainerLite>

                <GelContainerLite style={{ padding: "initial" }}>
                    <GelLink href={data?.supportGuidesLink} target="_blank"
                        onClick={() => onLinkClick(data?.supportGuidesLinkText)}>
                        <ImageContainer id="supportGuide" style={{ backgroundImage: `url(${data?.supportGuidesImage.url})` }}>
                            <GelContainerLite style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                <GelHeading5 style={{ color: themeColorBackgroundDefault }}>{data?.supportGuidesImage.title}</GelHeading5>
                                <GelParagraph style={{ color: themeColorBackgroundDefault }}>{data?.supportGuidesImage.description}</GelParagraph>
                            </GelContainerLite>
                        </ImageContainer>
                    </GelLink>

                    <GelParagraph style={{ padding: `${sizeBaseX4}px ${sizeNone}px` }}> {data?.supportGuidesSubtext}</GelParagraph>

                    <GelLink href={data?.supportGuidesLink} target="_blank"
                        onClick={() => onLinkClick(data?.supportGuidesLinkText)}>
                        {data?.supportGuidesLinkText}
                    </GelLink>
                </GelContainerLite>

            </GelColumnLayout>
        </GelContainerLite>
    )
};

export default memo(SupportInRecovery);
import React, { useContext, useEffect, useState } from 'react';
import {
    GelSpinner,
    GelScreenDetectorContext,
    GelContainerLite,
    GelHeading2,
    GelParagraph
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { UserContext } from '../../common/usercontext/user.context';
import styled from '@emotion/styled';
import { useCookies } from 'react-cookie';
import { useGetNoClaimDetailsPageContent } from '../../common/api/graphQLDataFetch';
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';

const NoClaims = () => {
    const { global: { sizeBaseX3, sizeNone, sizeBaseX15, sizeBaseX5, themeColorBackgroundLight, sizeBaseX10 } } = getGelTokens();


    const { screen, isXsScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const [{ tpid }] = useCookies(['tpid']);
    const { contextData } = useContext(UserContext)
    const [headerText, setheaderText] = useState('');
    const [subText, setsubText] = useState('');

    const { data, isLoading } = useGetNoClaimDetailsPageContent()

    if (headerText === '' && subText === '' && data) {
        if (contextData?.memberDetails?.memberId === 0) {
            setheaderText(data?.noClaimHeading);
            setsubText(data?.noClaimDescription?.json?.content[0].content[0].value)
        }
        else if (contextData?.memberDetails?.memberId! > 0) {
            setheaderText(data?.noActiveClaimHeading);
            setsubText(data?.noActiveClaimDescription?.json?.content[0].content[0].value)
        }
        else if (!contextData?.memberDetails?.memberId) {
            setheaderText(data?.noClaimHeading);
            setsubText(data?.noClaimDescription?.json?.content[0].content[0].value)
        }
    }

    useEffect(() => {
        AdobeAnalytics.PushPageViewEvent(tpid)
    }, []);

    const PageHeader = styled.div(() => ({
        padding: isXsScreen(screen) ? `${sizeBaseX15}px ${sizeBaseX5}px` : `${sizeBaseX15}px ${sizeBaseX10}px`,
        backgroundColor: themeColorBackgroundLight
    }));

    if (isLoading)
        return (<GelSpinner medium overlay />);

    return (
        <GelContainerLite style={{ padding: sizeNone }}>
            <PageHeader >
                <GelHeading2>{headerText}</GelHeading2>
                <GelParagraph style={{ paddingTop: sizeBaseX3 }}>
                    <div dangerouslySetInnerHTML={{ __html: subText }} />
                </GelParagraph>
            </PageHeader>
        </GelContainerLite >
    );
};

export default NoClaims;
import React, { useEffect, useState } from 'react';
import {
    GelBoxLayout,
    GelRowLayout,
    GelIcon,
    GelLabel
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { Link } from "react-router-dom";
import {
    NavItemList,
    NavMenuContainer,
    EnabledMenuStyle,
    ActiveMenuStyle,
    EnabledMenuLableStyle,
    ActiveMenuLableStyle,
    StyledListHeader,
    StyledListItem
} from "../nav-menu/styles/nav.styles";
import { NavProps } from "./nav-props";
import { useCookies } from 'react-cookie';
import DigiCorroBadge from './digiCorro-badge';
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';
import { AdobeClickType, MESSAGE_HUB_NAV_KEY } from '../../constants/constants';

const SideNav = ({ onMenuHideClick, textData, activeMenuItem }: NavProps) => {
    const { global: { themeColorWhite, sizeBaseUnit, themeColorGrayT30, themeColorBackgroundSuccess, fontWeightBold } } = getGelTokens();

    const [selectedMenuItem, setSelectedMenuItem] = useState(activeMenuItem);
    const [{ tpid }] = useCookies(["tpid"]);

    useEffect(() => {
        if (activeMenuItem) {
            setSelectedMenuItem(activeMenuItem);
        }
    }, [activeMenuItem]);

    const onMenuItemClick = (itemId: string) => {
        if (!itemId) {
            return
        }

        const parsedItemId = !isNaN(parseInt(itemId, 10)) ? parseInt(itemId, 10) : null;

        if (!parsedItemId) {
            return
        }

        const item = textData.menuItems.find((item: { id: number; }) => item.id === parsedItemId);
        setSelectedMenuItem(item);
        AdobeAnalytics.PushClickEvent(tpid, item.text, AdobeClickType.Sidebar)
    };

    return (
        <GelRowLayout >
            <GelBoxLayout>
                <StyledListHeader>
                    <GelLabel id="navMenuHeaderLabel" style={{
                        color: themeColorWhite,
                        fontWeight: fontWeightBold
                    }}
                    >
                        {textData.menuHeader}
                    </GelLabel>
                    <GelIcon id="menugridIcon"
                        name="MinimalLeft"
                        color={themeColorWhite}
                        width={sizeBaseUnit * 6}
                        style={{ float: "right" }}
                        onClick={() => onMenuHideClick()}
                    />
                </StyledListHeader>
            </GelBoxLayout>

            <NavMenuContainer>
                {textData.menuItems.map((menuItem) => (
                    <NavItemList key={menuItem.id}>
                        <Link to={menuItem.link} style={{ textDecoration: 'none' }} onClick={() => onMenuItemClick(`${menuItem.id}`)}>
                            <StyledListItem
                                style={(menuItem.id === selectedMenuItem?.id) ? ActiveMenuStyle : EnabledMenuStyle} >
                                <GelIcon
                                    name={menuItem.icon}
                                    color={(menuItem.id === selectedMenuItem?.id) ? themeColorBackgroundSuccess :
                                        themeColorGrayT30}
                                    width={sizeBaseUnit * 6}
                                    style={{ paddingLeft: sizeBaseUnit * 2 }}
                                />

                                <GelLabel id={menuItem.text} style={(menuItem.id === selectedMenuItem?.id) ? ActiveMenuLableStyle : EnabledMenuLableStyle}>
                                    {menuItem.text}
                                </GelLabel>
                                {menuItem.key === MESSAGE_HUB_NAV_KEY ? <DigiCorroBadge /> : null}
                            </StyledListItem>
                        </Link>
                    </NavItemList>
                ))}
            </NavMenuContainer>
        </GelRowLayout>
    );
};

export default SideNav;

import React, { useState } from 'react';
import {
    GelBoxLayout,
    GelRowLayout,
    GelIcon,
    GelLabel
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { Link } from "react-router-dom";
import {
    NavItemList,
    NavMenuMobContainer,
    EnabledMenuStyle,
    ActiveMenuStyle,
    EnabledMenuLableStyle,
    ActiveMenuLableStyle,
    StyledListHeader,
    StyledListItem
} from "../nav-menu/styles/nav.styles";
import { NavProps } from "./nav-props";
import { useCookies } from 'react-cookie';
import { AdobeAnalytics, } from '../../common/analytics/adobe-analytics';
import { AdobeClickType, MESSAGE_HUB_NAV_KEY } from '../../constants/constants';
import DigiCorroBadge from './digiCorro-badge';

//TODO: combine this component with top-nav-mob-mini
// TODO: Consider implementing `onClick` for the entire width of the navbar, rather than just within the icon area.
const TopNavMob = ({ onMenuHideClick, textData, activeMenuItem }: NavProps) => {
    const { global: { themeColorGrayT30, themeColorWhite, fontWeightBold, sizeBaseUnit, themeColorBackgroundSuccess } } = getGelTokens();


    const [selectedMenuItem, setSelectedMenuItem] = useState(activeMenuItem);
    const [{ tpid }] = useCookies(["tpid"]);

    const onMenuItemClick = (itemId: string) => {
        if (!itemId) {
            onMenuHideClick();
            return
        }

        const parsedItemId = !isNaN(parseInt(itemId, 10)) ? parseInt(itemId, 10) : null;

        if (!parsedItemId) {
            onMenuHideClick();
            return
        }

        const item = textData.menuItems.find((item: { id: number; }) => item.id === parsedItemId);
        setSelectedMenuItem(item);

        AdobeAnalytics.PushClickEvent(tpid, item.text, AdobeClickType.Sidebar)

        onMenuHideClick();
    };

    return (
        <GelRowLayout >
            <GelBoxLayout>
                <StyledListHeader>
                    <GelLabel style={{
                        color: themeColorWhite,
                        fontWeight: fontWeightBold
                    }}>
                        {selectedMenuItem ? selectedMenuItem.text.toUpperCase() : "NEED HELP"}
                    </GelLabel>
                    <GelIcon
                        name="MinimalUp"
                        width={sizeBaseUnit * 6}
                        color={themeColorWhite}
                        style={{ float: "right" }}
                        onClick={() => onMenuHideClick()}
                    />
                </StyledListHeader>
            </GelBoxLayout>

            <NavMenuMobContainer>
                {textData.menuItems.map((menuItem) => (
                    <NavItemList key={menuItem.id}>
                        <Link to={menuItem.link} style={{ textDecoration: 'none' }} onClick={() => onMenuItemClick(`${menuItem.id}`)}>
                            <StyledListItem
                                style={(menuItem.id === selectedMenuItem?.id) ? ActiveMenuStyle : EnabledMenuStyle} >
                                <GelIcon
                                    name={menuItem.icon}
                                    color={(menuItem.id === selectedMenuItem?.id) ? themeColorBackgroundSuccess :
                                        themeColorGrayT30}
                                    width={sizeBaseUnit * 6}
                                    style={{ paddingLeft: sizeBaseUnit * 2 }}
                                />

                                <GelLabel id={menuItem.text} style={(menuItem.id === selectedMenuItem?.id) ? ActiveMenuLableStyle : EnabledMenuLableStyle}>
                                    {menuItem.text}
                                </GelLabel>

                                {menuItem.key === MESSAGE_HUB_NAV_KEY ? <DigiCorroBadge /> : null}
                            </StyledListItem>
                        </Link>
                    </NavItemList>
                ))}
            </NavMenuMobContainer>
        </GelRowLayout>
    );
};

export default TopNavMob;
import React, { memo, useEffect, useState } from "react";
import {
    GelParagraph,
    GelCollapsible,
    GelContainerLite,
    GelBoxLayout,
    GelLabel,
    GelIcon
} from '@tal-gel/components';
import { FormatHelper } from "../../../common/formatHelper.common";
import { useLocation } from "react-router-dom";
import { AdobeClickType, NotificationItemIds } from "../../../constants/constants";
import { getGelTokens } from '@tal-gel/theming';
import { useCookies } from "react-cookie";
import { AdobeAnalytics } from "../../../common/analytics/adobe-analytics";
import { useGetClaimDetailsContent } from "../../../common/api/graphQLDataFetch";

const NextFuturePayment = (props) => {
    const { global: { sizeBaseUnit, themeColorBorderDefault, fontFamilySans, fontWeightRegular, themeColorIconDefault } } = getGelTokens()

    const location = useLocation();
    const [{ tpid }] = useCookies(['tpid']);
    const { data } = useGetClaimDetailsContent()

    const [showFuturePayment, setshowFuturePayment] = useState(false);



    useEffect(() => {
        const selectedNotification = location.state?.selectedNotification;
        setshowFuturePayment(selectedNotification == NotificationItemIds.FuturePayment);
    }, []);

    const onHeaderClick = () => {
        setshowFuturePayment(!showFuturePayment);

        AdobeAnalytics.PushClickEvent(tpid, data?.nextFuturePaymentHeader, AdobeClickType.Button)
    }


    return (
        <div id={NotificationItemIds.FuturePayment}>
            <GelContainerLite style={{
                borderTop: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                borderLeft: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                borderRight: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                paddingTop: sizeBaseUnit,
                paddingBottom: sizeBaseUnit,
                width: "auto",
                cursor: "pointer"
            }}
                onClick={onHeaderClick}>

                <GelBoxLayout space={[7, 0.1, 0.04]} style={{
                    borderBottom: showFuturePayment ? `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}` : "",
                    width: "auto",
                    padding: sizeBaseUnit * 2,
                    marginLeft: -sizeBaseUnit * 4,
                    marginRight: -sizeBaseUnit * 4,
                }}>
                    <GelLabel style={{
                        fontFamily: fontFamilySans,
                        fontSize: sizeBaseUnit * 4,
                        fontWeight: fontWeightRegular
                    }}>
                        {data?.nextFuturePaymentHeader}
                    </GelLabel>

                    <GelIcon name={showFuturePayment ? "ChevronUp" : "ChevronDown"} color={themeColorIconDefault} width="20px" height="20px"></GelIcon>
                </GelBoxLayout>
            </GelContainerLite>

            <GelContainerLite style={{
                borderBottom: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                borderLeft: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                borderRight: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                width: "auto"
            }}>

                <GelCollapsible open={showFuturePayment}>
                    <GelContainerLite style={{ padding: sizeBaseUnit * 4 }}>
                        <GelParagraph>
                            {FormatHelper.stringFormat(data?.nextFuturePaymentBody,
                                props.futurePayment?.friendlyText, props.futurePayment?.description)}
                        </GelParagraph>
                    </GelContainerLite>
                </GelCollapsible>

            </GelContainerLite>
        </div>
    )
};

export default memo(NextFuturePayment);
import React, { useContext } from "react";
import {
    GelRowLayout,
    GelParagraph,
    GelHeading3,
    GelHeading5,
    GelContainerLite,
    GelLink,
    GelColumnLayout,
    GelScreenDetectorContext
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import styled from "@emotion/styled";
import { useCookies } from "react-cookie";
import { AdobeAnalytics } from "../../common/analytics/adobe-analytics";
import { AdobeClickType } from "../../constants/constants";
import { useGetHealthAndSupportPageContent } from "../../common/api/graphQLDataFetch";

const SupportLovedOnes: React.FC = () => {
    const { global: { sizeNone, themeColorBackgroundDefault, sizeBaseX9, sizeBaseX2, sizeBaseX10, sizeBaseX4 } } = getGelTokens();

    const [{ tpid }] = useCookies(["tpid"]);
    const { screen, isLargerThanLgScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;

    const { data } = useGetHealthAndSupportPageContent()

    const ImageContainer = styled.div`
        position: relative; 
        text-align: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 168px;
        width: 100%;
        background-position: center;
        border-radius: ${sizeBaseX2}px;
    `

    const onLinkClick = (linkText) => {
        AdobeAnalytics.PushClickEvent(tpid, linkText, AdobeClickType.Link)
    }

    return (
        <GelContainerLite style={{ padding: `${sizeBaseX10}px ${sizeNone}px` }}>

            <GelRowLayout style={{ paddingBottom: sizeBaseX9 }}>
                <GelHeading3>{data?.supportLovedOnesHeader}</GelHeading3>
            </GelRowLayout>

            <GelColumnLayout distribution={!isLargerThanLgScreen(screen) ? "1" : "1/3 1/3 1/3"}>

                <GelContainerLite style={{ padding: "initial", maxHeight: "362px" }}>
                    <GelLink href={data?.carerAssistLink} target="_blank"
                        onClick={() => onLinkClick(data?.carerAssistLinkText)}>
                        <ImageContainer id="carerAssist" style={{ backgroundImage: `url(${data?.carerAssistImage.url})` }}>
                            <GelContainerLite style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                <GelHeading5 style={{ color: themeColorBackgroundDefault }}>{data?.carerAssistImage.title}</GelHeading5>
                                <GelParagraph style={{ color: themeColorBackgroundDefault }}>{data?.carerAssistImage.description}</GelParagraph>
                            </GelContainerLite>
                        </ImageContainer>
                    </GelLink>

                    <GelParagraph style={{ padding: `${sizeBaseX4}px ${sizeNone}px` }}> {data?.carerAssistSubtext}</GelParagraph>

                    <GelLink href={data?.carerAssistLink} target="_blank"
                        onClick={() => onLinkClick(data?.carerAssistLinkText)}>
                        {data?.carerAssistLinkText}</GelLink>
                </GelContainerLite>

                <GelContainerLite style={{ padding: "initial", maxHeight: "362px" }}>
                    <GelLink href={data?.griefSupportLink} target="_blank"
                        onClick={() => onLinkClick(data?.griefSupportLinkText)}>
                        <ImageContainer id="griefSupport" style={{ backgroundImage: `url(${data?.griefSupportImage.url})` }}>
                            <GelContainerLite style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                <GelHeading5 style={{ color: themeColorBackgroundDefault }}>{data?.griefSupportImage.title}</GelHeading5>
                                <GelParagraph style={{ color: themeColorBackgroundDefault }}>{data?.griefSupportImage.description}</GelParagraph>
                            </GelContainerLite>
                        </ImageContainer>
                    </GelLink>

                    <GelParagraph style={{ padding: `${sizeBaseX4}px ${sizeNone}px` }}> {data?.griefSupportSubtext}</GelParagraph>

                    <GelLink href={data?.griefSupportLink} target="_blank"
                        onClick={() => onLinkClick(data?.griefSupportLinkText)}>
                        {data?.griefSupportLinkText}</GelLink>
                </GelContainerLite>

                <GelContainerLite style={{ padding: "initial", maxHeight: "362px" }}>
                    <GelLink href={data?.doDLink} target="_blank"
                        onClick={() => onLinkClick(data?.doDLinkText)}>
                        <ImageContainer id="doD" style={{ backgroundImage: `url(${data?.doDImage.url})` }}>
                            <GelContainerLite style={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                <GelHeading5 style={{ color: themeColorBackgroundDefault }}>{data?.doDImage.title}</GelHeading5>
                                <GelParagraph style={{ color: themeColorBackgroundDefault }}>{data?.doDImage.description}</GelParagraph>
                            </GelContainerLite>
                        </ImageContainer>
                    </GelLink>

                    <GelParagraph style={{ padding: `${sizeBaseX4}px ${sizeNone}px` }}>{data?.doDSubtext}</GelParagraph>

                    <GelLink href={data?.doDLink} target="_blank"
                        onClick={() => onLinkClick(data?.doDLinkText)}>
                        {data?.doDLinkText}</GelLink>
                </GelContainerLite>

            </GelColumnLayout>
        </GelContainerLite>
    )
};

export default SupportLovedOnes;
import React, { memo, useState } from 'react';
import styled from '@emotion/styled';
import {
    GelTooltip,
    GelRowLayout,
    GelLabel,
    GelContainerLite,
    GelCollapsible
} from '@tal-gel/components';
import BenefitType from '../../dashboard/benefitType';
import { getGelTokens } from '@tal-gel/theming';
import IPDetails from './ip-details';
import TPDDetails from './tpd-details';
import { CLAIM_TYPE } from '../../../constants/constants';
import { useGetClaimDetailsContent } from '../../../common/api/graphQLDataFetch';

const ClaimBenefit = (props) => {
    const { global: { sizeNone, sizeBaseX2, sizeBase, sizeBaseUnit, themeColorBorderDefault } } = getGelTokens()

    const [showBenefitType, setShowBenefitType] = useState(false);

    const { data } = useGetClaimDetailsContent()


    const onHeaderClick = () => {
        setShowBenefitType(!showBenefitType);
    }
    const StyledDiv = styled.div(() => ({
        padding: "0px",
        paddingBottom: "20px",
        width: sizeBase * 50
    }));

    const StyledGelLabel = styled(GelLabel)(() => ({

        marginRight: sizeBaseX2
    }));

    const StyledGelTooltip = styled(GelTooltip)(() => ({
        paddingBottom: 5
    }));

    return (
        <GelContainerLite style={{
            borderTop: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
            width: "auto",
            cursor: "pointer",
            padding: sizeNone
        }}
            onClick={onHeaderClick}>

            <GelRowLayout style={{
                borderBottom: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,
                borderTop: `${sizeBaseUnit / 4}px solid ${themeColorBorderDefault}`,

            }}>

                <BenefitType claimNumber={props.claimBenefit.caseNumber} description={props.claimBenefit.status.description}
                // <BenefitType claimNumber={props.claimBenefit.caseNumber} description={props.claimBenefit.status.description}
                    friendlyStatus={props.claimBenefit.status.friendlyStatus}
                    benefitTypeText={props.claimBenefit.claimTypeName} showBenefitType={showBenefitType} isExpandable={true} />
            </GelRowLayout>

            <GelCollapsible open={showBenefitType}>
                <GelContainerLite style={{ padding: "25px 40px" }}>
                    <GelRowLayout >
                        <StyledDiv>
                            <StyledGelLabel>{data?.detailsHeader}</StyledGelLabel>
                            {(props.claimBenefit.claimTypeName === CLAIM_TYPE.IP) && <StyledGelTooltip selfAdaptive inline>
                                {data?.disclaimer}
                            </StyledGelTooltip>}
                        </StyledDiv>
                    </GelRowLayout>

                    {(props.claimBenefit.claimTypeName === CLAIM_TYPE.IP) &&
                        <IPDetails details={props.claimBenefit.details} caseManager={props.claimBenefit.caseManager} contentData={props.contentData} />}
                    {(props.claimBenefit.claimTypeName === CLAIM_TYPE.TPD || props.claimBenefit.claimTypeName === CLAIM_TYPE.TI) &&
                        <TPDDetails details={props.claimBenefit.details} caseManager={props.claimBenefit.caseManager} contentData={props.contentData} />}
                </GelContainerLite>
            </GelCollapsible>

        </GelContainerLite>
    );
};

export default memo(ClaimBenefit);
import React from 'react';
import {
    GelBoxLayout,
    GelContainerLite,
    GelIcon,
    GelLink,
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { AdobeClickType, CLAIM_TYPE, NotificationItemIds } from '../../constants/constants';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';

const NextPayment = (Props) => {
    const { global: { sizeBaseX3, themeColorBackgroundLight, themeColorGrayT10, sizeBaseX2, themeColorBackgroundDark, sizeBaseUnit } } = getGelTokens()

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [{ tpid }, setClaimCookie] = useCookies(['selectedClaim', 'tpid']);
    const navigate = useNavigate();

    const navigateToDetails = () => {
        const concatedLinkName = Props.message + " " + Props.futurePayments.netAmount
        AdobeAnalytics.PushClickEvent(tpid, concatedLinkName, AdobeClickType.Link)

        setClaimCookie('selectedClaim', Props.claimType);
        navigate("/claimdetails", { state: { selectedNotification: NotificationItemIds.FuturePayment } });
    };

    const requirements =
        Props.isFuturePayment && Props.claimType == CLAIM_TYPE.IP &&
        <GelBoxLayout alignmentIndividual={['center', null]} space={[0.1, 7]}
            style={{
                backgroundColor: themeColorBackgroundLight,
                paddingTop: sizeBaseX3,
                paddingBottom: sizeBaseX3,
                border: `0px solid ${themeColorGrayT10}`,
                borderWidth: `0px 0px 1px`
            }}>
            <GelIcon
                name="Cost"
                width={sizeBaseUnit * 5}
                color={themeColorBackgroundDark} style={{ marginLeft: sizeBaseX2 }} />
            <GelLink onClick={navigateToDetails}>{Props.message}{Props.futurePayments.netAmount}</GelLink>
        </GelBoxLayout>


    return (
        <GelContainerLite gutter="medium" style={{
            paddingTop: 0,
            paddingBottom: sizeBaseX2 * 1,
            paddingLeft: sizeBaseX2 * 1,
            paddingRight: sizeBaseX2 * 1,
        }}>
            {requirements}
        </GelContainerLite>
    );
};

export default NextPayment;
import React, { useContext, useEffect, useState } from 'react';
import {
  GelForm, GelFormField, GelTextInput,
  GelIcon,
  useGelFormData,
  GelButton,
  GelBoxLayout,
  GelScreenDetectorContext,
  GelContainerLite,
  GelLabel,
  GelLink,
  GelSelect,
  GelRowLayout,
  GelStandardDateInput,
  GelReCaptcha,
  GelSpinner,
  GelHeading3,
  GelParagraph
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { AdobeClickType, API, ReCaptchaSettings } from '../../constants/constants';
import FundBanner from "../../components/fundBanner";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { REGISTER_USER } from '../../graphql/queries/graphql-mutations';
import { UserRegistrationStatus } from '../../common/models/accountStatus.model';
import { useCookies } from 'react-cookie';
import SESSION_STORAGE_KEY from '../../constants/storage.constant';
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';
import { useGetSignUpPageContentData, useGetSuperfundData } from "../../common/api/graphQLDataFetch"

const SignUpPage = () => {
  const { global: { sizeNone, sizeBaseUnit, sizeBaseX2, themeColorTextDanger, themeColorIconSuccess, themeColorIconDanger, themeColorTextSuccess } } = getGelTokens()

  const signUpPageData = useGetSignUpPageContentData()
  const superFundData = useGetSuperfundData()

  const [superfundId, setSuperFund] = useState(null);
  const { screen, isXsScreen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
  const navigate = useNavigate();
  const [registerStatusMessage, setregisterStatusMessage] = useState("");
  const [registerSuccessMessage, setregisterSuccessMessage] = useState("");
  const [status, setStatus] = useCookies(['signUpStatus']);
  const [registerInProgress, setregisterInProgress] = useState(false);

  let isCaptchaEnabled = ReCaptchaSettings.ReCaptcha_Enabled == "1";

  const {
    formData,
    onFormDataChange,
  } = useGelFormData({
    reCaptchaToken: null,
    superFund: '',
    memberNumber: '',
    dateOfBirth: ''
  });

  const getSuperFundNameForAdobeAnalytics = (): string => {
    return superFundData.data?.superFundList?.find(
      item => item.superfundId.toString() === superfundId
    )?.superFundPrefix.toLowerCase() ?? "";
  };

  const onSubmit = () => {

    AdobeAnalytics.PushClickEvent(getSuperFundNameForAdobeAnalytics(), signUpPageData.data?.continueButtonText, AdobeClickType.Button)

    window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_SIGNUP, "");

    let requestData = {
      memberNumber: formData.memberNumber,
      dateOfBirth: formData.dateOfBirth,
      superFundId: superfundId
    };

    setregisterInProgress(true);
    setregisterStatusMessage("");

    axios.post(API.BASEURL, {
      query: REGISTER_USER,
      variables: {
        registerUserRequest: requestData
      }
    },
      {
        headers: {
          're-captcha': formData.reCaptchaToken
        }
      })
      .then(async (response) => {
        setregisterInProgress(false);

        if (response.data.data?.registerUser?.statusCode == UserRegistrationStatus.MEMBER_NOT_FOUND) {
          setStatus('signUpStatus', signUpPageData.data?.errorMessageContent.memberNotFoundMsg);
          window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_SIGNUP, "reload");
          navigate(0);
        }
        else if (response.data.data?.registerUser?.statusCode == UserRegistrationStatus.USER_ACTIVATION_EMAIL_SENT)
          setregisterSuccessMessage(signUpPageData.data?.errorMessageContent.registerSuccessMsg);
        else if (response.data.data?.registerUser?.statusCode == UserRegistrationStatus.USER_ALREADY_ACTIVE ||
          response.data.data?.registerUser?.statusCode == UserRegistrationStatus.USER_ALREADY_EXISTS)
          setregisterStatusMessage(signUpPageData.data?.errorMessageContent.userAlreadyActiveMsg);
        else
          setregisterStatusMessage(signUpPageData.data?.errorMessageContent.registerFailedMsg);
      })
  };

  const navigateToSignIn = () => {
    AdobeAnalytics.PushClickEvent(getSuperFundNameForAdobeAnalytics(), signUpPageData.data?.signInLink, AdobeClickType.Link)

    window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_SIGNUP, "");
    setStatus('signUpStatus', "");

    navigate("/login");
  };

  const onSuperFundChange = event => {
    setSuperFund(event.target.value);
  }

  useEffect(() => {
    AdobeAnalytics.PushPageViewEvent("")

    if (window.sessionStorage.getItem(SESSION_STORAGE_KEY.RELOAD_SIGNUP) == "reload") {
      setregisterStatusMessage(status.signUpStatus);
      window.sessionStorage.setItem(SESSION_STORAGE_KEY.RELOAD_SIGNUP, "");
    }

  }, []);

  if (!signUpPageData.isFetched || !superFundData.isFetched)
    return <GelSpinner medium overlay />

  return (
    <GelContainerLite style={{
      paddingRight: sizeNone,
      paddingLeft: sizeNone,
      paddingBottom: sizeBaseUnit * 15,
      paddingTop: sizeBaseUnit * 15,
      overflow: "hidden"
    }}>
      <GelBoxLayout alignment="start" space={[1, 4]} gutter="none">
        <GelForm
          labelAtTop={true}
          width={isXsScreen(screen) ?
            '100%' : sizeBaseUnit * 110
          }
          {...!isXsScreen(screen) && {
            labelWidth: sizeBaseUnit * 50
          }}
          fieldGutter={sizeBaseUnit * 10}
          disableOnSubmit
          onSubmit={onSubmit}
          parseResponseBody={false}
          style={{
            paddingRight: sizeBaseUnit * 16,
            paddingLeft: sizeBaseUnit * 16
          }}>
          <GelRowLayout gutter="medium" style={{ paddingLeft: sizeBaseUnit * 3 }}>
            <GelHeading3>
              {signUpPageData.data?.headerText}
            </GelHeading3>
            <GelParagraph style={{ paddingTop: sizeBaseUnit }}>{signUpPageData.data?.subText}</GelParagraph>

            <GelFormField
              label={signUpPageData.data?.superFundText}
            >
              <GelSelect
                name="superfund"
                onChange={onSuperFundChange}
                placeholder="Please select"
                options={superFundData.data?.superFundList?.filter(superFund => superFund.hideInDropDown == false)?.map((superFund) => (
                  { label: superFund.superfundName, value: superFund.superfundId }
                ))}
                required
                requiredErrorMsg={signUpPageData.data?.errorMessageContent.superFundReqMsg}
              />
            </GelFormField>

            <GelFormField label={signUpPageData.data?.memberNumberText} >
              <GelTextInput
                name="memberNumber"
                onChange={onFormDataChange}
                required
                value={formData.memberNumber}
                requiredErrorMsg={signUpPageData.data?.errorMessageContent.memberNumberReqMsg} />
            </GelFormField>

            <GelFormField
              label={signUpPageData.data?.dateOfBirthText} >
              <GelStandardDateInput
                name="dateOfBirth"
                value={formData.dateOfBirth}
                onChange={onFormDataChange}
                required
                lt="9999-12-31"
                errorMsg={{
                  required: `${signUpPageData.data?.errorMessageContent.dobReqMsg}`,
                  compare: `${signUpPageData.data?.errorMessageContent.dobFormatMsg}`,
                  format: `${signUpPageData.data?.errorMessageContent.dobFormatMsg}`
                }}
              />
            </GelFormField>
            {isCaptchaEnabled &&
              <GelFormField
                label={signUpPageData.data?.captchaText}>
                <GelReCaptcha
                  name="reCaptchaToken"
                  sitekey={ReCaptchaSettings.ReCaptcha_SiteKey}
                  onChange={onFormDataChange}
                  errorMsg={{
                    required: `${signUpPageData.data?.errorMessageContent.captchaReqMsg}`,
                  }}
                />
              </GelFormField>}

            {registerStatusMessage &&
              <GelBoxLayout space={[1, 8]}>
                <GelIcon color={themeColorIconDanger} name="AlertCircle" inline />
                <GelLabel style={{ color: themeColorTextDanger }}>
                  {registerStatusMessage}
                </GelLabel>
              </GelBoxLayout>
            }
            {registerSuccessMessage &&
              <GelBoxLayout space={[1, 8]}>
                <GelIcon color={themeColorIconSuccess} name="CheckSmall" inline />
                <GelLabel style={{ color: themeColorTextSuccess }}>
                  {registerSuccessMessage}
                </GelLabel>
              </GelBoxLayout>
            }
            {!registerInProgress &&
              <GelFormField width="auto" style={{ paddingTop: sizeBaseUnit * 4 }}>
                <GelButton
                  name="" style={{
                    width: "100%"
                  }}
                  primary large submit
                >
                  {signUpPageData.data?.continueButtonText}
                </GelButton>
              </GelFormField>}

            {registerInProgress &&
              <GelSpinner medium overlay={true} />}

            <GelLabel style={{
              fontWeight: 100,
              paddingRight: sizeBaseX2
            }}>
              {signUpPageData.data?.signInText}
              <GelLink onClick={navigateToSignIn}>  {signUpPageData.data?.signInLink}</GelLink>
            </GelLabel>
          </GelRowLayout>
        </GelForm>
        {isLargerThanSmScreen(screen) &&
          <FundBanner />}
      </GelBoxLayout>
    </GelContainerLite>
  );
}

export default SignUpPage;
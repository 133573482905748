import {
  GelContainerLite,
  GelBoxLayout,
  GelLabel,
  GelIcon,
  GelLink,
  GelSpinner
} from
  '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { useNavigate } from 'react-router-dom';
import AuthenticationService from '../common/authentication/auth.service';
import { useGetErrorPagesContent } from '../common/api/graphQLDataFetch';
import { useCookies } from 'react-cookie';
import { AdobeAnalytics } from "../common/analytics/adobe-analytics"
import { AdobeClickType } from '../constants/constants';
import { useEffect } from 'react';

const UnAuthorized = () => {
  const { global: { themeColorTextDanger } } = getGelTokens()

  const { data, isFetched } = useGetErrorPagesContent()
  const navigate = useNavigate();
  const [{ tpid }] = useCookies(['tpid']);

  const { isStandAloneAuthenticated, clearSession } = AuthenticationService();

  useEffect(() => {
    AdobeAnalytics.PushPageViewEvent(tpid)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const navigateToLogin = () => {
    AdobeAnalytics.PushClickEvent(tpid, data?.loginLinkText, AdobeClickType.Link)

    clearSession();
    navigate("/login");
  };

  if (!isFetched)
    return <GelSpinner medium overlay />;

  return (
    <GelContainerLite gutter="xlarge">
      <GelBoxLayout space="auto">
        <GelLabel style={{ color: themeColorTextDanger }}>
          <GelIcon color={themeColorTextDanger} name="AlertCircle" inline />

          {data?.unAuthorizedMessage}
        </GelLabel>

        {isStandAloneAuthenticated && <GelLink onClick={navigateToLogin}>
          {data?.loginLinkText}
        </GelLink>
        }
      </GelBoxLayout>
    </GelContainerLite>
  );
}

export default UnAuthorized;
/**
 * pushRouterHistory
 *
 * This function disables the browser's back button functionality by 
 * programmatically pushing multiple empty states into the browser's 
 * history stack.
 *
 * Implementation Details:
 * - It uses the `window.history.pushState` method to add 30 entries 
 *   to the history stack. This number was chosen due to throttling 
 *   restrictions in Chromium-based browsers.
 * - By saturating the history stack, the back button becomes 
 *   ineffective, as the user can only navigate through these empty states.
 *
 * Limitations:
 * - This approach may not be effective in all browsers or scenarios 
 *   due to variations in how browsers handle history manipulation.
 * - Modern browsers may implement safeguards against excessive 
 *   history manipulation to protect the user experience.
 *
 * Additional Note:
 * - If you want this function to execute faster, especially during the 
 *   constructor phase (e.g., component initialization), you can call it 
 *   outside of a `useEffect` hook. This ensures it runs immediately 
 *   without waiting for the React lifecycle to kick in.
 *
 * Usage:
 * - Call `pushRouterHistory()` to execute the function.
 * - Ensure it aligns with user experience and accessibility considerations, 
 *   as overriding navigation can frustrate users.
 */
export const pushRouterHistory = () => {
    // The number of iterations, set to 30, was chosen due to throttling restrictions in Chromium-based browsers
    for (let i = 0; i < 30; i++) {
        window.history.pushState("", "", "");
    }
};

export const isTokenActive = (token: string | null | undefined): boolean => {
    if (!token) return false; // If there's no token, consider it inactive.

    try {
        const base64Url = token.split('.')[1]; // Get the payload
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = JSON.parse(atob(base64));

        if (jsonPayload.exp) {
            const expirationTime = jsonPayload.exp * 1000; // Convert from seconds to milliseconds
            const currentTime = Date.now(); // Get the current time in milliseconds

            return expirationTime > currentTime; // True if the token is still active
        }
    } catch (_) {
        return false; // If decoding fails or `exp` is not found, assume the token is inactive
    }

    return false; // Default case if `exp` is missing
}

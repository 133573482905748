import React, { useContext, useEffect } from 'react';
import { AdobeClickType, API } from '../../constants/constants';
import {
    GelScreenDetectorContext,
    GelRowLayout,
    GelContainerLite,
    GelParagraph,
    GelHeading3,
    GelBoxLayout,
    GelButton,
    GelSpinner
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';
import { UserContext } from '../../common/usercontext/user.context';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import FundBanner from "../../components/fundBanner";
import { SAVE_LEGAL_DISCLOSURE_QUERY } from '../../graphql/queries/graphql-mutations';
import SESSION_STORAGE_KEY from '../../constants/storage.constant';
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';
import { ACTIVATION_EMAIL } from '../../graphql/queries/graphql-mutations';
import { useGetYourDeclarationPageContent } from '../../common/api/graphQLDataFetch';


const YourDeclaration = () => {
    const { global: { sizeNone, sizeBaseX3, sizeBaseX7, sizeBaseUnit } } = getGelTokens();

    const navigate = useNavigate();
    const { screen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
    const { contextData, setUserContext } = useContext(UserContext);
    const [{ aid, rid, ssoid, tpid }] = useCookies(['aid', 'rid', 'ssoid', 'tpid']);
    const { data, isFetched } = useGetYourDeclarationPageContent()

    if (!contextData.memberDetails?.displayLegalDisclosure) {
        navigate("/error");
    }

    useEffect(() => {

        if (window.sessionStorage.getItem(SESSION_STORAGE_KEY.SEND_ACTIVATION_EMAIL)) {
            sendSuccessfulActivationEmail();
        }

        AdobeAnalytics.PushPageViewEvent(tpid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const sendSuccessfulActivationEmail = () => {
        let requestData = {
            userName: window.sessionStorage.getItem(SESSION_STORAGE_KEY.SEND_ACTIVATION_EMAIL)
        };
        axios.post(API.BASEURL, {
            query: ACTIVATION_EMAIL,
            variables: {
                memberActivationSuccessModel: requestData
            }
        },
            {
                headers: {
                    'x-aid': aid,
                    'x-rid': rid,
                    ssoid,
                    tpid
                }
            })
            .then(async (response) => {
                if (response) {
                    console.log("Activation email sent")
                }
                else {
                    console.log("Failed to send activation email")
                }
                window.sessionStorage.setItem(SESSION_STORAGE_KEY.SEND_ACTIVATION_EMAIL, "");
            })
            .catch(httpError => {
                console.log('Failed to send successful activation email', httpError);
            });
    }

    const onContinue = () => {
        AdobeAnalytics.PushClickEvent(tpid, data?.buttonText, AdobeClickType.Button)

        axios.post(API.BASEURL, {
            query: SAVE_LEGAL_DISCLOSURE_QUERY,
        },
            {
                headers: {
                    'x-aid': aid,
                    'x-rid': rid,
                    ssoid,
                    tpid,
                    'sid': window.sessionStorage.getItem(SESSION_STORAGE_KEY.SESSIONID_TOKEN)
                }
            })
            .then(async (response) => {
                if (response.data?.data?.acceptLegalDisclosureForMemberClaims === true) {
                    if (contextData.memberDetails) {
                        contextData.memberDetails.displayLegalDisclosure = false;
                        setUserContext(contextData);
                    }
                    navigate("/claims");
                }
            });
    };

    if (!isFetched)
        return (<GelSpinner medium overlay />)

    return (
        <GelContainerLite style={{
            paddingRight: sizeNone,
            paddingLeft: sizeNone,
            paddingBottom: sizeBaseUnit * 15,
            paddingTop: sizeBaseUnit * 15,
            overflow: "hidden"
        }}>
            <GelBoxLayout space={[1, 2]} style={{
                alignItems: "baseLine"
            }}>
                <GelRowLayout id="yourDeclaration" style={{ padding: `${sizeBaseX3}px ${sizeBaseUnit * 16}px` }}>
                    <GelHeading3 style={{ paddingTop: sizeBaseX7 }}>
                        {data?.pageHeader}
                    </GelHeading3>

                    <GelParagraph>
                        {data?.declarationStatement}
                    </GelParagraph>

                    <GelParagraph style={{ paddingBottom: sizeBaseX7 }}>
                        {data?.declarationStatement2}
                    </GelParagraph>

                    <GelButton
                        name="" // Makes it a managed component
                        style={{ width: "100%" }}
                        primary large submit
                        onClick={onContinue}
                    >
                        {data?.buttonText}
                    </GelButton>
                </GelRowLayout>

                {isLargerThanSmScreen(screen) &&
                    <FundBanner />}
            </GelBoxLayout>
        </GelContainerLite>
    )

};

export default YourDeclaration  
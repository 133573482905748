import React, { } from "react";
import {
    GelContainerLite,
    GelIcon,
    GelBoxLayout,
    GelTag,
    GelParagraph
} from '@tal-gel/components';
import { getGelTokens } from '@tal-gel/theming';

export enum StatusTypeEnum {
    Success = "success",
    Warning = "warning",
    Danger = "danger"
}

interface Props {
    statusHeader: string;
    statusDescription: string;
    statusType: StatusTypeEnum
}

const ClaimStatus = ({ statusHeader, statusDescription, statusType }: Props) => {
    const { global: { sizeBaseUnit, sizeBaseX3, themeColorTextDark, sizeBaseX2 },
        tags: { tagSuccessIconColor, tagDangerIconColor, tagWarningIconColor } } = getGelTokens()

    const getStatusTag = (status: StatusTypeEnum) => {
        switch (status) {
            case StatusTypeEnum.Success:
                return <GelTag small success>
                    <GelBoxLayout distribution='center'
                        alignment='center'
                        space={[0.5, 10]}
                        gutter={2}>
                        <GelIcon
                            name="Circle" inline medium
                            width={sizeBaseUnit * 2}
                            color={tagSuccessIconColor}
                            marginBottom={3} />
                        <GelParagraph style={{ fontSize: sizeBaseX3, color: themeColorTextDark }}>{statusHeader}</GelParagraph>
                    </GelBoxLayout>
                </GelTag>;
            case StatusTypeEnum.Danger:
                return <GelTag small danger>
                    <GelBoxLayout distribution='center'
                        alignment='center'
                        space={[0.5, 10]}
                        gutter={2}>
                        <GelIcon name="Circle" inline medium
                            width={sizeBaseUnit * 2}
                            color={tagDangerIconColor}
                            marginBottom={3} />
                        <GelParagraph style={{ fontSize: sizeBaseX3, color: themeColorTextDark }}>{statusHeader}</GelParagraph>
                    </GelBoxLayout>
                </GelTag>;
            case StatusTypeEnum.Warning:
                return <GelTag small warning>
                    <GelBoxLayout distribution='center'
                        alignment='center'
                        space={[0.5, 10]}
                        gutter={2}>
                        <GelIcon name="Circle" inline medium
                            width={sizeBaseUnit * 2}
                            color={tagWarningIconColor}
                            marginBottom={3} />
                        <GelParagraph style={{ fontSize: sizeBaseX3, color: themeColorTextDark }}>{statusHeader}</GelParagraph>
                    </GelBoxLayout>
                </GelTag>;
            default:
                return <></>;
        }
    };

    return (
        <GelContainerLite style={{ paddingBottom: sizeBaseX2 }}>
            {getStatusTag(statusType)}
            <GelParagraph style={{ padding: `${sizeBaseX2}px ${sizeBaseUnit}px` }}>
                {statusDescription}
            </GelParagraph>
        </GelContainerLite>);
};

export default ClaimStatus;
import React, { useContext, useEffect, useState } from 'react';
import {
  GelForm, GelFormField,
  GelPasswordInput,
  useGelFormData,
  GelButton,
  GelBoxLayout,
  GelScreenDetectorContext,
  GelContainerLite,
  GelRowLayout,
  GelIcon,
  GelLabel,
  GelLink,
  GelHeading3,
  GelParagraph,
  GelSpinner,
  GelList,
  GelListItem
} from '@tal-gel/components';

import { getGelTokens } from '@tal-gel/theming';
import { TRANSACTION_STATUS } from '../../common/authentication/auth.utils';
import { useNavigate } from "react-router-dom";
import { AccountApi } from '../../common/api/auth.provider';
import { UserContext } from '../../common/usercontext/user.context';
import { AdobeClickType } from '../../constants/constants';
import FundBanner from "../../components/fundBanner";
import { AdobeAnalytics } from '../../common/analytics/adobe-analytics';
import { useCookies } from 'react-cookie';
import { useGetActivatePageContentData } from '../../common/api/graphQLDataFetch';

const CreatePassword = () => {
  const { global: { sizeNone, sizeBaseUnit, sizeBaseX3, themeColorBackgroundDanger, themeColorTextDanger, sizeBaseX2 } } = getGelTokens()

  const navigate = useNavigate();
  const { screen, isXsScreen, isLargerThanSmScreen } = useContext(GelScreenDetectorContext) as GelScreenDetectorContext;
  const [loginStatusMessage, setloginStatusMessage] = useState("");
  const { setUserContext } = useContext(UserContext);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [{ tpid }] = useCookies(["tpid"])

  const { data, isLoading } = useGetActivatePageContentData()

  const {
    formData,
    onFormDataChange,
    onFormDataReset,
    resetForm
  } = useGelFormData({
    password: '',
    confirmPassword: ''
  });

  const { contextData } = useContext(UserContext)

  useEffect(() => {
    AdobeAnalytics.PushPageViewEvent(tpid)

  }, []);

  const onSubmit = () => {
    AdobeAnalytics.PushClickEvent(tpid, data?.activateButtonText, AdobeClickType.Button)

    AccountApi.resetPassword(formData.password, contextData.memberDetails?.stateToken!)
      .then(response => response.json())
      .then(data => {
        const { status } = data;
        if (status.toString() === TRANSACTION_STATUS.MFA_REQUIRED) {
          let mfaStateToken = data.stateToken;
          console.log('Valid credentials, MFA Required');
          //User has been enrolled in a sms factor, retreive factorId and send MFA
          const factor = data._embedded.factors.find(f => f.factorType === 'sms');
          const factorId = factor?.id;
          if (!factor) {
            console.error(`User not enrolled in sms factor`);
          }
          // Extract members phone number from sms factor
          const phoneNumber = factor?.profile.phoneNumber.replaceAll(' ', '').replaceAll('X', '*');
          setUserContext({ memberDetails: { factorId: factorId, stateToken: mfaStateToken, mobileNumber: phoneNumber, oktaEmail: contextData.memberDetails?.oktaEmail }, claims: [] });

          if (phoneNumber)
            navigate("/confirmMobile");
          else
            navigate("/confirmMember");
        }
        else {
          //Unrecognised state, after successfully resetting password user should be prompted for MFA
          //this.setState({errorMessage: 'EnrollSmsFactorRequestNotAllowed'});
        }
      }).catch(httpError => {
        console.error('reset password failed errorMessage:', httpError);
        setloginStatusMessage("Create password failed");
      });
  };

  const handlePasswordInput = (event) => {
    // Check if passwords match    
    if (formData.confirmPassword) {
      setPasswordsMatch(formData.confirmPassword == event.target.value)
    }
  };

  const handleConfirmPasswordInput = (event) => {
    // Check if passwords match
    setPasswordsMatch(formData.password && formData.password == event.target.value);
  };

  const navigateToLogin = () => {
    AdobeAnalytics.PushClickEvent(tpid, data?.signInLink, AdobeClickType.Link)
    navigate("/login");
  };

  if (isLoading)
    return (<GelSpinner medium overlay />)

  return (
    <GelContainerLite style={{
      paddingRight: sizeNone,
      paddingLeft: sizeNone,
      paddingBottom: sizeBaseUnit * 15,
      paddingTop: sizeBaseUnit * 15, overflow: "hidden"
    }}>
      <GelBoxLayout alignment="start" space={[1, 4]} gutter="none" >
        <GelForm
          labelAtTop={true}
          width={isXsScreen(screen) ?
            '100%' : sizeBaseUnit * 110
          }
          {...!isXsScreen(screen) && {
            labelWidth: sizeBaseUnit * 50
          }}
          fieldGutter={sizeBaseUnit * 10}
          disableOnSubmit
          onSubmit={onSubmit}
          reset={resetForm}
          onReset={onFormDataReset}
          parseResponseBody={false}
          style={{
            paddingRight: sizeBaseUnit * 16,
            paddingLeft: sizeBaseUnit * 16
          }}
        >
          <GelRowLayout gutter="medium" style={{ paddingLeft: sizeBaseUnit * 3 }}>
            <GelHeading3>
              {data?.headerText}
            </GelHeading3>

            <GelParagraph style={{ paddingTop: sizeBaseUnit * 2 }}>
              {data?.subText}
            </GelParagraph>

            <GelContainerLite style={{ backgroundColor: "#D5F0F0", padding: "16px" }}>
              <GelLabel >{data?.passwordRequirementsHeader}</GelLabel>

              <GelParagraph>{data?.passwordRequirementsSubtext}</GelParagraph>

              <GelList style={{ paddingTop: "10px" }}>
                {data?.passwordRequirementsList.map((req) => (
                  <GelListItem key={crypto.randomUUID()}>{req}</GelListItem>
                ))}
              </GelList>
            </GelContainerLite>

            <GelFormField
              label={data?.passwordText}
            >
              <GelPasswordInput
                name="password"
                value={formData.password}
                onChange={onFormDataChange}
                onInput={handlePasswordInput}
                required
                requiredErrorMsg={data?.errorMessageContent.passwordRequiredMsg}
                errorMsg={{}}
              />
            </GelFormField>

            <GelFormField
              label={data?.confirmPasswordText}
            >
              <GelPasswordInput
                name="confirmPassword"
                value={formData.confirmPassword}
                onChange={onFormDataChange}
                onInput={handleConfirmPasswordInput}
                required
                errorMsg={{
                  required: data?.errorMessageContent.confirmPasswordReqMsg
                }}
              />

              <GelParagraph style={{ color: themeColorBackgroundDanger, fontSize: sizeBaseX3, paddingTop: sizeBaseUnit }}>
                {!passwordsMatch && data?.errorMessageContent.passwordMatchMsg}
              </GelParagraph>

            </GelFormField>

            <GelButton
              name="" style={{
                width: "100%"
              }}
              primary large submit
            >
              {data?.activateButtonText}
            </GelButton>

            <GelBoxLayout space="auto" >
              {loginStatusMessage &&
                <GelLabel style={{ color: themeColorTextDanger }}>
                  <GelIcon color={themeColorTextDanger} name="AlertCircle" inline />

                  {data?.errorMessageContent.passwordReqMsg}
                </GelLabel>}
            </GelBoxLayout>

            <GelLabel style={{
              fontWeight: 100,
              paddingRight: sizeBaseX2
            }}>
              {data?.signInText}
              <GelLink onClick={navigateToLogin}>
                {data?.signInLink}
              </GelLink>
            </GelLabel>
          </GelRowLayout>
        </GelForm>
        {isLargerThanSmScreen(screen) &&
          <FundBanner />}
      </GelBoxLayout>
    </GelContainerLite>
  );
};

export default CreatePassword;